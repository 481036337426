import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import { SearchFilter } from './SearchFilter';
import { SortTable } from "./BlitzTable";
import { Col, Container, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { uiConstants } from "../../_constants";

const SelectIcon = styled(FontAwesomeIcon)`
    color: ${uiConstants.CASSETTE_COLOR};
`;

const ContainerStyled = styled(Container)`
    padding: 0px;
    color: #BBB;
    user-select: none;
`;

export const ReactTooltipStyled = styled(ReactTooltip)`
    background: white !important;
    color: black !important;
    border: 1px solid grey !important;

    .place-left::after {
        border-left: 8px solid grey !important;
    }

    .place-right::after {
        border-right: 8px solid grey !important;
    }

    .place-top::after {
        border-top: 8px solid grey !important;
    }

    .place-bottom::after {
        border-bottom: 8px solid grey !important;
    }


`;

export const SelectionContextMenuSuggested = ({selectedIds, onListItemClicked, suggestionData, songsData}) => {
  const [filter, setFilter] = useState('');
  const handleFilter = text => {
    setFilter(text);
  }

  const filteredPlaylists = suggestionData.filter(playlist => playlist.playlistName.toLowerCase().indexOf(filter.toLowerCase()) !== -1)

  let columns = [
    {
      percent: 0.1, rowRenderer: row => {
        const selected = selectedIds.includes(row.playlist2Id);
        return selected && (<SelectIcon icon={['fal', 'check']} fixedWidth />)
      }
    },
    {
      percent: 1, field: 'playlistName', title: 'NAME', rowRenderer: row => {
        const tooltipId = `playlist_info_${row.playlist2Id}`;
        return <>
                    <div data-tip data-for={tooltipId}>{row.playlistName}</div>
                    <ReactTooltipStyled effect='solid' place="bottom" type='light' id={tooltipId}>
                        CLIENT <br />
                      {row.clientName}
                    </ReactTooltipStyled>
                </>
      }
    },
    {percent: 0.3, field: 'common', title: 'COMMON', isNumber: true},
    {percent: 0.3, field: 'tracks', title: 'TRACKS', isNumber: true},
    {percent: 0.3, field: 'percent', title: 'PERCENT', isNumber: true},
  ];

  return (
      <ContainerStyled>
            <Row>
                <Col style={{maxHeight: 600, overflowY: "scroll"}}>
                    <Row>
                        <Col xs={10}>
                            <SearchFilter onChange={handleFilter} value={filter} />
                        </Col>
                        <Col xs={2} style={{display: "flex"}} className="align-items-center">
                            <Row data-tip data-for='songs_list'>
                                <Col>{songsData.length} selected
                                    <ReactTooltipStyled effect='solid' place="bottom" type='light' id='songs_list'>
                                        {songsData.map(song => <>{song.title}<br /></>)}
                                    </ReactTooltipStyled>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SortTable
                                tableName={'playlist_suggested'}
                                defaultSortField='percent'
                                defaultSortOrder='desc'
                                loading={false}
                                idField='playlist2Id'
                                rows={filteredPlaylists}
                                onRowClick={row => onListItemClicked(row)}
                                columns={columns}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ContainerStyled>
  )
}