import React from "react";
import styled, {ThemeProvider} from "styled-components";

import {DefaultColumnContainer, DefaultRowContainer} from './Container.style';
import {DefaultLabel} from './Label.style';
import {DefaultButton} from './Button.style';
import {DefaultAlert} from './Alert.style';
import {LeftIconFormControl} from './Input.style';
import {theme} from "./Theme";

import {InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import image from "_assets/images/cassette_logo.png";
import {RowSpaced} from "../_components";

const Container = styled(DefaultColumnContainer)`
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.main};
  width: 100%;
  height: 100%;
  min-width: 800px;
`;

const InnerContainer = styled(DefaultColumnContainer)`
  width: 30%;
  align-items: center;
`;

const Image = styled.img`
  display: flex;
  width: 50%;
  margin: auto auto;
  margin-bottom: 40px;
`;

const IGroup = styled(InputGroup)`
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 5px;
  background-color: ${props => props.theme.darkGray};
  color: white;

  & + & {
    margin-top: 20px;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.optionalGray};
    color: white;
  }
`;

const IGroupPrepend = styled(InputGroup.Prepend)``;

const IGroupText = styled(InputGroup.Text)`
  border: 0;
  color: white;
  background-color: transparent;
`;

const LoginForm = styled(LeftIconFormControl)``;

const TextContainer = styled(DefaultRowContainer)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

const Text = styled(DefaultLabel)`
  color: ${props => props.theme.lightGray};
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
`;

const Button = styled(DefaultButton)`
  margin-top: 30px;
  padding: 10px;
  font-weight: bold;
  width: 100%;
`;

export const LoginVerificationContainer = ({
  alert,
  verificationCode,
  onChange,
  handleSubmit
}) => (
  <ThemeProvider theme={theme}>
    <Container>
      <InnerContainer>
        <form onSubmit={e => {e.preventDefault(); handleSubmit();}}>
        <Image src={image} />
         <RowSpaced style={{marginBottom:20}}>
          <DefaultLabel style={{color:"white",display:"table",margin:"0 auto"}}>A verification code has been set to your email account.<br/>Please enter it below</DefaultLabel>
         </RowSpaced>
        <IGroup>
          <IGroupPrepend>
            <IGroupText id="basic-addon1">
              <Icon icon="id-card" />
            </IGroupText>
          </IGroupPrepend>
          <LoginForm
            name="validationCode"
            type="text"
            placeholder="Verification code"
            aria-label="Verification code"
            aria-describedby="basic-addon1"
            value={verificationCode}
            onChange={onChange}
          />
        </IGroup>
        <Button onClick={handleSubmit}>Submit</Button>
        {/* Have enter key perform submit without rewiring above button */}
        <input type="submit" style={{display: "none"}} />
        <DefaultAlert style={{marginTop:10}} color={'error'}>{alert}&nbsp;</DefaultAlert>
        </form>
      </InnerContainer>
    </Container>
  </ThemeProvider>
);
