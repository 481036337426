import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isLoadingList } from "../../_reducers/playlist.reducer";
import { DefaultButton, DefaultColumnContainer } from "../../_styles";
import albumImagePlaceholder from "_assets/images/talea-album-placeholder.png";
import "react-multi-carousel/lib/styles.css";
import { Check, Comment, ThumbUp, Warning } from "@material-ui/icons";
import { Button, Col, Container, Image, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { sampleActions } from "../../_actions/sample.actions";

import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import media from "../../_helpers/media";

import { SamplesModal } from "./SamplesStyledWidgets";
import { uiConstants } from "../../_constants";
import PlaylistPlayImage from "../../_assets/images/playlist_play_icon.png";
import UpButtonArrow from "../../_assets/images/up_button_arrow.png";
import CassetteSignatureImage from "../../_assets/images/cassette_signature_image.png";
import CASSETTE_LOGO_IMAGE from "../../_assets/images/cassette_logo.png";
import CASSETTE_LOGO_GEAR_IMAGE from "../../_assets/images/cassette_logo_gear.png";

const BrandContainerCol = styled(Col)`
    align-items: stretch;
    justify-content: center;
    padding: 30px 0;
`;

const SampleInfo = styled(DefaultColumnContainer)`
    ${media.mobile`    
  padding: 15px 0px 15px 0px; 
`};
    ${media.tablet`
padding: 0px 0px 15px 30px;
`}
    color: white;
`;

const PlaylistsContainer = styled(Container)`
    ${media.mobile`    
    width: 100% 
`};
    ${media.tablet`
    width: 100%
`}
`;
const ClientName = styled.span`
    font-size: 12.5px;
    font-family: 'Apercu';
    text-transform: uppercase;
    background: white;
    padding: 5px 5px 5px 5px;
    color: black;
`;
const SampleName = styled.span`
    ${media.mobile`    
  font-size: 24px; 
`};
    ${media.tablet`
font-size: 58px;
`}

`;

const SubmitFeedbackButton = styled(Button)`
    background-color: transparent;
    border-color: white;
    color: white;
    width: 158px;
    height: 39px;
    border-radius: 25px;
    padding: 5px 10px 5px 10px;
    font-size: 12.5px;
    font-family: 'Apercu';

    :hover, :focus {
        color: black;
        background-color: ${uiConstants.CASSETTE_COLOR};
        outline: none !important;
        box-shadow: none;
        border-color: transparent;
    }

`

const SampleDescription = styled.span`
    font-family: "ApercuMono";
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
    align-items: flex-start;
    color: white;
`;

const PlaylistImageContainer = styled.div`
    position: relative;
    ${media.mobile`    
  width: 33px;
  height: 33px;
`};
    ${media.tablet`
width: 33px;
height: 33px;
`}

    object-fit: fit;
    color: white;
`;

const PlayOverlay = styled(Image)`
    :hover {
        cursor: pointer;
    }

    ${media.mobile`    
  width: 30px;
  height: 30px;
`};

    ${media.tablet`    
  width: 30px;
  height: 30px;
`};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

const PlaylistImage = styled.img`
    :hover {
        cursor: pointer;
    }

    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: fill;
    color: white;
`;
const PlaylistName = styled.div`
    ${media.mobile`    
  font-size: 24px;  
`};
    ${media.tablet`
font-size: 24px;
`}
    font-family: "Apercu";
    color: white;
    text-align: left;
`;


const PlaylistDescription = styled.div`
    font-family: "ApercuMono";
    font-size: 12.5px;
    margin-top: 5px;
    line-height: 20px;
    color: #AEAEAE;
    text-align: left;
`;

const Brand = styled.img`
    width: 150px;
`;

export const VotesBadge = styled.div`
    font-family: "Apercu";
    font-weight: 400;
    display: flex;
    width: 75px;
    height: 22px;
    border-radius: 25px;
    font-size: 12.5px;
    justify-content: center;
    align-items: center;
    color: ${props => props.novotes ? "white" : "black"};
    background-color: ${props => props.novotes ? "transparent" : uiConstants.CASSETTE_COLOR};
    border: 1px solid ${props => props.novotes ? "white" : uiConstants.CASSETTE_COLOR};
`;

const SampleImage = styled.img`
    ${media.mobile`    
  width: 45px;  
  height: 45px;  
`};
    ${media.tablet`
width: 100px;
`}

    border: 0;
`;

const BorderRow = styled(Row)`
    margin-top: 40px;
    border-top: 1px solid #3C3C47 !important;
    position: absolute;
    width: 100%;
    left: 0;
`;

const PlaylistTitleRow = styled(Row)`
    ${media.mobile`                        
          margin-left:5px;   
    `};
    ${media.tablet`                    
          margin-left:30px;
    `};
    margin-bottom: 30px;
    font-size: 32px;
    color: white;
`;


const SubmitFeedbackButtonRow = styled(Row)`
    ${media.mobile`    
          margin-top: 80px;
`};
    ${media.tablet`
         margin-top: 80px;          
`};
`

const PlaylistRow = styled(Row)`
    ${media.mobile`                        
          margin-bottom: 15px;   
`};
    ${media.tablet`                    
          margin-bottom: 15px;
`};

    :hover {
        cursor: pointer;
    }
`
const BorderCol = styled(Col)`
    border-top: 1px solid #3C3C47 !important;
`;

export function getSamplePlaylistElements(sample) {
  const samplePlaylists = sample.playlists ? sample.playlists : [];
  const samplePlaylistSortsDtos = sample.playlistSortDtos ? sample.playlistSortDtos : [];
  const samplePlaylistElements = [...samplePlaylists];
  samplePlaylistSortsDtos.forEach(playlistSort => {
    let playlist = {
      id: playlistSort.playlistId,
      name: playlistSort.playlistName,
      description: playlistSort.playlistDescription,
      artworkUrl: playlistSort.artworkUrl
    };
    playlist.sort = playlistSort;
    samplePlaylistElements.push(playlist);
  });
  return samplePlaylistElements;
}

export function getSamplePlaylistElementId(samplePlaylistElement) {
  return samplePlaylistElement.id + (samplePlaylistElement.sort ? "_" + samplePlaylistElement.sort.id : "");
}


class Sample extends React.Component {
  state = {
    id: "",
    currentlyPlaying: "",
    name: "",
    showFeedbackModal: false,
    showInstructionsModal: (localStorage.getItem("shownSampleInfoModal") === null),
  };

  componentDidMount() {
    const action = sampleActions.getSample(this.props.sampleId);
    this.props.dispatch(action);
  }

  componentDidUpdate() {
  }

  submitFeedbackFormClicked(name, feedback) {
    sampleActions.postSampleFeedback(this.props.dispatch, this.props.sample.token, feedback, name);
  }

  InstructionsModal = () => {
    const hideInstructionsModal = () => {
      this.setState({showInstructionsModal: false});
      localStorage.setItem("shownSampleInfoModal", "1");
    }

    return <SamplesModal centered show={true} backdrop={true} size={'small'}
        onHide={hideInstructionsModal}>
            <Modal.Header style={{
              backgroundColor: uiConstants.CASSETTE_DIALOG_BACKGROUND_COLOR,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: 0,
              borderRadius: "15px 15px 0 0",
              color: "#F3ECEC"
            }} closeButton>
                <Modal.Title style={{
                  width: "100%", paddingLeft: 37, textAlign: "center"
                }}>Instructions on using Talea Samples</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{
              backgroundColor: uiConstants.CASSETTE_DIALOG_BACKGROUND_COLOR,
              color: "#F3ECEC",
              borderRadius: "0 0 15px 15px",
            }}>
                <>
                    <Row xs={12} className="align-items-center d-flex justify-content-center"
                        style={{marginBottom: 20}}>
                        <Col xs={"auto"} className={"d-none d-sm-block"}>
                            <Avatar style={{
                              background: "#424052",
                              padding: 30,
                              height: 20,
                              width: 20,
                              color: "white",
                              borderRadius: "50%"
                            }}>
                                <Image style={{width: 20, height: 20}} src={PlaylistPlayImage}></Image>
                            </Avatar>
                        </Col>
                        <Col xs={"auto"} className={"d-none d-sm-block"}>
                            ---------
                        </Col>
                        <Col xs={"auto"}>
                            <Avatar style={{
                              background: "#424052",
                              padding: 30,
                              height: 125,
                              width: 125,
                              color: "white",
                              borderRadius: "50%"
                            }}>
                                <ThumbUp fontSize={"large"}></ThumbUp>
                            </Avatar>
                        </Col>

                    </Row>
                    <Row style={{marginBottom: 40}}>
                        <Col style={{lineHeight: "39.78px", fontSize: 20, fontWeight: 400, padding: 30}}>
                            <ol>
                                <li>
                                    Listen through all playlists.
                                </li>
                                <li>
                                    Vote and comment on the at least 1 track per playlist.
                                </li>
                                <li>
                                    After listening and voting - <span
                                    style={{color: uiConstants.CASSETTE_COLOR}}>Submit your feedback</span> for our curators to review.
                                </li>
                            </ol>
                            <p>If you have any questions, please contact our support at <span
                                style={{color: uiConstants.CASSETTE_COLOR}}>contact@talea-agency.com</span> for assistance.</p>
                        </Col>
                    </Row>
                    <Row>
                        <DefaultButton
                            style={{
                              width: 150,
                              height: 35,
                              border: 0,
                              color: "black",
                              backgroundColor: "#EFEFEF",
                              fontWeight: 500,
                              margin: "auto",
                              marginBottom: 15
                            }}
                            onClick={hideInstructionsModal}>
                            Ok
                        </DefaultButton>
                    </Row>
                </>
            </Modal.Body>
        </SamplesModal>
  }

  FeedbackModal = () => {
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [feedback, setFeedback] = useState('');
    return <SamplesModal centered show={true} backdrop={true} size={'small'}
        onHide={() => {
          this.setState({showFeedbackModal: false});
        }}>
            <Modal.Header style={{
              backgroundColor: uiConstants.CASSETTE_DIALOG_BACKGROUND_COLOR,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: 0,
              borderRadius: "15px 15px 0 0",
              color: "#F3ECEC"
            }} closeButton>
                <Modal.Title style={{
                  width: "100%", paddingLeft: 37, textAlign: "center"
                }}>{step === 1 ? "SUBMIT FEEDBACK" : "Thanks for listening"}</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{
              backgroundColor: uiConstants.CASSETTE_DIALOG_BACKGROUND_COLOR,
              color: "#F3ECEC",
              borderRadius: "0 0 15px 15px",
            }}>
                {step === 0 ? <>
                        <Row xs={12} className="align-items-center d-flex justify-content-center"
                            style={{marginBottom: 40}}>
                            <Col xs={"auto"} className={"d-none d-sm-block"}>
                                <Avatar style={{
                                  background: uiConstants.CASSETTE_COLOR,
                                  padding: 30,
                                  height: 125,
                                  width: 125,
                                  color: "black",
                                  borderRadius: "50%"
                                }}>
                                    <ThumbUp fontSize={"large"}></ThumbUp>
                                </Avatar>
                            </Col>
                            <Col xs={"auto"} className={"d-none d-sm-block"}>
                                ---------
                            </Col>
                            <Col xs={"auto"}>
                                <Avatar style={{
                                  background: "#424052",
                                  padding: 30,
                                  height: 125,
                                  width: 125,
                                  color: uiConstants.CASSETTE_COLOR,
                                  borderRadius: "50%"
                                }}>
                                    <Warning></Warning>
                                </Avatar>
                            </Col>

                        </Row>
                        <Row style={{marginBottom: 40}}>
                            <Col style={{lineHeight: "1.3rem"}}>
                                Hold up, You still haven’t listened to all the playlists in the sample yet - are you
                                sure want to submit feedback now?
                            </Col>
                        </Row>
                        <Row>
                            <DefaultButton
                                style={{
                                  width: 150,
                                  height: 35,
                                  border: 0,
                                  color: "black",
                                  backgroundColor: "#EFEFEF",
                                  fontWeight: 600,
                                  margin: "auto",
                                  marginBottom: 15
                                }}
                                onClick={() => {
                                  setStep(1);
                                }}>
                                Next
                            </DefaultButton>
                        </Row>
                    </>

                    : step === 1 ? <>
                        <Row xs={12} className="align-items-center d-flex justify-content-center"
                            style={{marginBottom: 40}}>
                            <Col xs={"auto"} className={"d-none d-sm-block"}>
                                <Avatar style={{
                                  background: uiConstants.CASSETTE_COLOR,
                                  padding: 30,
                                  height: 125,
                                  width: 125,
                                  color: "black",
                                  borderRadius: "50%"
                                }}>
                                    <ThumbUp fontSize={"large"}></ThumbUp>
                                </Avatar>
                            </Col>
                            <Col xs={"auto"} className={"d-none d-sm-block"}>
                                ---------
                            </Col>
                            <Col xs={"auto"}>
                                <Avatar style={{
                                  background: "#424052",
                                  padding: 30,
                                  height: 125,
                                  width: 125,
                                  color: "white",
                                  borderRadius: "50%"
                                }}>
                                    <Comment></Comment>
                                </Avatar>
                            </Col>
                        </Row>
                        <Row style={{margin: "auto", fontfamily: "Apercu"}}>
                            <Col style={{lineHeight: "1.3rem"}}>
                                Thanks for listening! Do you have any final thoughts or comments before submitting
                                your
                                feedback?
                            </Col>
                        </Row>
                        <Row style={{margin: "auto", marginTop: 20}}>
                            <Col>
                                    <textarea value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Name*" style={{
                                      resize: "none",
                                      overflow: "hidden",
                                      height: 40,
                                      width: "100%",
                                      borderRadius: "15px",
                                      margin: "auto",
                                      padding: 20,
                                      paddingTop: 12,
                                      backgroundColor: "#424052",
                                      color: "#F3ECEC"
                                    }} />
                            </Col>
                        </Row>
                        <Row style={{margin: "auto", marginTop: 20}}>
                            <Col>
                    <textarea onChange={(event) => setFeedback(event.target.value)}
                        value={feedback}
                        placeholder={"Leave a comment..."}
                        style={{
                          resize: "none",
                          height: 150,
                          borderRadius: "15px",
                          width: "100%",
                          margin: "auto",
                          padding: 20,
                          backgroundColor: "#424052",
                          color: "#F3ECEC"
                        }}></textarea>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 25}}>
                            <DefaultButton
                                style={{
                                  width: 225,
                                  height: 35,
                                  border: 0,
                                  color: "black",
                                  backgroundColor: uiConstants.CASSETTE_COLOR,
                                  fontfamily: "Apercu",
                                  fontWeight: 500,
                                  margin: "auto",
                                  marginBottom: 15
                                }}
                                onClick={() => {
                                  setStep(2);
                                  this.submitFeedbackFormClicked(name, feedback);
                                }}>
                                Submit Feedback
                            </DefaultButton>
                        </Row>
                    </> : <>
                        <Row xs={12} className="align-items-center d-flex justify-content-center"
                            style={{marginBottom: 20}}>
                            <Col xs={"auto"}>
                                <Avatar style={{
                                  background: "#00B200",
                                  padding: 30,
                                  height: 125,
                                  width: 125,
                                  color: "black",
                                  borderRadius: "50%"
                                }}>
                                    <Check fontSize={"large"}></Check>
                                </Avatar>
                            </Col>
                        </Row>
                        <Row style={{margin: "auto", marginBottom: 20}}>
                            <Col style={{lineHeight: "1.3rem"}}>
                                Thank you {name}!<br /><br />
                                Your feedback has been submitted! We’ll be in touch shortly.<br /><br />
                                You can keep on listening to the sample as much as you like.<br /><br />
                                Talea Team.

                            </Col>
                        </Row>
                        <Row>
                            <DefaultButton
                                style={{
                                  width: 150,
                                  height: 35,
                                  border: 0,
                                  color: "black",
                                  backgroundColor: "#EFEFEF",
                                  fontWeight: 600,
                                  margin: "auto",
                                  marginBottom: 15
                                }}
                                onClick={() => {
                                  setFeedback("");
                                  setName("");
                                  this.setState({showFeedbackModal: false});
                                }}>
                                Ok
                            </DefaultButton>
                        </Row>
                    </>}
            </Modal.Body>
        </SamplesModal>
  }

  submitFeedbackClicked = (name, feedback) => {
    this.setState({showFeedbackModal: true, name: name, feedback: feedback});
  }


  playlistRow(playlist) {
    const voteCount = this.props.sample.votes.filter(v => v.playlist && v.playlist.id === playlist.id).length;
    const playlistId = getSamplePlaylistElementId(playlist);
    const playlistName = playlist.name + (playlist.sort ? "_" + playlist.sort.name : "");
    return <><PlaylistRow
        onClick={() => this.props.history.push(`/clientsample/${this.props.sample.token}/playlist/${playlistId}`)}>
            <Col xs={"auto"} style={{padding: 0}}>
                <PlaylistImageContainer>
                  <PlaylistImage
                      src={PlaylistPlayImage}></PlaylistImage>
                </PlaylistImageContainer>
            </Col>
            <Col xs={8} style={{paddingLeft: 25}}>
                <PlaylistName title={playlistName}>{playlist.name}</PlaylistName>
                <PlaylistDescription>{playlist.description ? playlist.description.toUpperCase : "DRUM AND BASS WITH SOME ELECTRONIC SOUNDS"}</PlaylistDescription>
            </Col>
            <Col xs={1}>
                <VotesBadge novotes={voteCount === 0}>{voteCount} VOTES</VotesBadge>
            </Col>
        </PlaylistRow>
            <PlaylistRow style={{marginBottom: 20}}>
                <BorderCol className={"border-top"}></BorderCol>
            </PlaylistRow>
        </>;
  }

  render() {
    const {
      sample
    } = this.props;
    const samplePlaylistElements = getSamplePlaylistElements(sample);

    const client = sample.client || {};
    const renderSubmitFeedbackTooltip = (props) => (
        <Tooltip style={{backgroundColor: "white", color: "black"}} id="button-tooltip" {...props}>
                Submit your feedback when you finish listening to all samples
            </Tooltip>
    );

    const entering = (e) => {
      e.children[1].style.backgroundColor = 'white';
      e.children[1].style.color = 'black';
    };
    return (<>
            <Container style={{marginTop: 0}}>
                <Row className={"d-flex justify-content-center"} style={{paddingLeft: 45, paddingTop: 30}}>
                  <Col sm={8} xs={8}>
                    <Row>
                      <img src={CASSETTE_LOGO_GEAR_IMAGE} style={{height: 46, width: 46}} />

                      <img src={CASSETTE_LOGO_IMAGE} className="d-none d-sm-block"
                          style={{height: 46, width: 496, marginLeft: 30}} />
                   </Row>
                  </Col>
                  <Col sm={4} xs={4} className="d-flex justify-content-end">
                        <SampleImage src={sample.artworkUrl || albumImagePlaceholder} alt="image"
                            style={{width: 45, height: 45}} />
                  </Col>
                </Row>
                <Row style={{marginTop: 50}} className={"d-flex justify-content-center"}>
                    <Col xs={12} sm={6}>
                        <SampleInfo>
                          <div>
                            <ClientName>{client.name}</ClientName>
                            </div>
                            <br />
                            <SampleName>{sample.name}</SampleName>
                            <br />
                        </SampleInfo>
                    </Col>
                  <Col xs={12} sm={6}>
                    <Row><SampleDescription>{sample.description ? sample.description.toUpperCase() : ""} </SampleDescription></Row>
                    <Row><img src={CassetteSignatureImage} style={{marginTop: 28, width: 360}}></img></Row>
                    <SubmitFeedbackButtonRow>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{show: 250, hide: 400}}
                                    overlay={renderSubmitFeedbackTooltip}
                                    onEntering={entering}
                                >
                                    <SubmitFeedbackButton onClick={this.submitFeedbackClicked}>
                                        SUBMIT FEEDBACK&nbsp;&nbsp;
                                      <img src={UpButtonArrow} style={{paddingBottom: 2, width: 10, height: 10}} />
                                      {/*<ThumbUp style={{marginRight: 10}}></ThumbUp>*/}
                                    </SubmitFeedbackButton>
                                </OverlayTrigger>
                        </SubmitFeedbackButtonRow>
                  </Col>
                </Row>
                <BorderRow></BorderRow>
                <Row style={{marginTop: 60}}>
                  <Col xs={12} sm={6}>
                    <PlaylistTitleRow>Playlists
                        </PlaylistTitleRow>
                  </Col>
                  <Col xs={12} sm={6}>
                    <PlaylistsContainer>
                      {samplePlaylistElements.map((playlist) => this.playlistRow(playlist, sample))}
                    </PlaylistsContainer>
                    </Col>
                </Row>
              <Row>
               <Row>
                 <Col xs={12} className="d-flex justify-content-center">
                   {/*<Lottie*/}
                   {/*  options={{*/}
                   {/*    loop: true,*/}
                   {/*    autoplay: true,*/}
                   {/*    animationData: require("../../_assets/animations/sample-animation.json"),*/}
                   {/*    rendererSettings: {*/}
                   {/*      preserveAspectRatio: "xMidYMid slice",*/}
                   {/*    },*/}
                   {/*  }}*/}
                   {/*  height={150}*/}
                   {/*  width={150}*/}
                   {/*/>*/}
                 </Col>
               </Row>
              </Row>
              {this.state.showFeedbackModal && <this.FeedbackModal></this.FeedbackModal>}
              {this.state.showInstructionsModal && <this.InstructionsModal></this.InstructionsModal>}
            </Container>
        </>);
  }
}

const mapStateToProps = (state, props) => ({
  sampleId: props.match.params.sampleToken,
  loading: isLoadingList(state),
  sample: state.samples.sample,
  alert: state.alert,
});

const connectedSample = withRouter(connect(mapStateToProps)(Sample));
export { connectedSample as Sample };