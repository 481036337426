import React from "react";
import styled from "styled-components";
import {DefaultRowContainer} from "./Container.style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { uiConstants } from "../_constants";

const ReviewButtonContainer = styled(DefaultRowContainer)`
  cursor: ${props => props.enabled ? "pointer" : "not-allowed"};
	height: 38px;
	width: 121px;
  border-radius: 21px;
  border: 1px solid ${props => props.enabled ? uiConstants.CASSETTE_COLOR : "#4B4B4B"};
  background-color: ${props => props.enabled ? uiConstants.CASSETTE_COLOR : "transparent"};
  justify-content: center;
  align-items: center;
`;

const ReviewButtonTitle = styled.div`
  color: ${props => props.enabled ? "white" : "#4B4B4B"};
  margin: 0;
  user-select: none;
  margin-right: 12px;
`;

export const ReviewButton = ({ enabled, onClick }) => (
  <ReviewButtonContainer enabled={enabled} onClick={enabled ? onClick : () => { }}>
    <ReviewButtonTitle enabled={enabled}>Publish</ReviewButtonTitle>
    <FontAwesomeIcon icon="chevron-right" color={enabled ? "white" : "#4B4B4B"} />
  </ReviewButtonContainer>
);