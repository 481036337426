import React, { useEffect, useState } from "react";
import { DropdownFilter, Icon, IconHover, IconInfo, IconPlay, SortTable } from "./Standard";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ModalDecisionBuilder } from "./Utils";
import { TextField } from "@material-ui/core";
import { playlistActions } from "../_actions";
import { defineTrackLength } from "../_helpers";
import { playlistService } from "../_services";
import styled from "styled-components";
import { uiConstants } from "../_constants";

const IconArrowUp = props => <Icon icon={['fas', 'arrow-up']} {...props} />;
const IconArrowDown = props => <Icon icon={['fas', 'arrow-down']} {...props} />;

const StyledTextField = styled(TextField)`
    /* default */

    .MuiInput-underline:before {
        border-bottom: 2px solid white;
    }

    /* hover (double-ampersand needed for specificity reasons. */

    && .MuiInput-underline:hover:before {
        border-bottom: 2px solid white;
    }

    /* focused */

    .MuiInput-underline:after {
        border-bottom: 2px solid white;
    }
`;

const ClickableInput = ({value, sortedSongs, onChange}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const switchToEdit = () => {
    setIsEditing(true);
  }
  const switchToView = () => {
    setIsEditing(false);
  }
  return isEditing ?
      <TextField inputProps={{style: {color: "white"}}} style={{color: "white"}} value={inputValue} onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (e.target.value.match(/^\d+$/)) {
            onChange(e.target.value);
          }
        } else {
          setInputValue(e.target.value);
        }
      }} onChange={
        (e) => {
          if (e.target.value.match(/^\d+$/) || e.target.value === "") {
            if (e.target.value === "" || (e.target.value > 0 && e.target.value <= sortedSongs.length)) {
              setInputValue(e.target.value);
            }
          }
        }}
          onBlur={switchToView} autoFocus></TextField> : <div style={{color: "white"}} onClick={switchToEdit}>{value}</div>;
}

export const SongsSortComponent = ({playlistId, playlistSongs, dispatch}) => {
  const [sortedSongs, setSortedSongs] = useState([...playlistSongs]);
  const [playlistSortOrders, setPlaylistSortOrders] = useState([]);
  const [selectedList, setSelectedList] = useState();
  const [showSave, setShowSave] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [sortTableKey, setSortTableKey] = useState(0);

  const loadPlaylistSortOrders = () => {
    playlistService.getPlaylistSortOrders(playlistId).then(playlistSorts => {
      setSelectedList(undefined);
      setPlaylistSortOrders(playlistSorts);
    });
  }

  useEffect(() => {
    loadPlaylistSortOrders();
  }, []);


  const moveSongUp = (index, amount) => {
    // move song x amount of times up in the list. Move all the sortedSongs in between 1 step down.
    if (index === 0) return;
    let song = sortedSongs[index];
    let i = index;
    let targetIndex = Math.max(index - amount, 0);
    while (i > targetIndex && i > 0) {
      sortedSongs[i] = sortedSongs[i - 1];
      i--;
    }
    sortedSongs[targetIndex] = song;
    setSortedSongs(sortedSongs);
    setSortTableKey(sortTableKey + 1);
  }

  const moveSongDown = (index, amount) => {
    // move song x amount of times down in the list. Move all the sortedSongs below the new song position 1 step down.
    if (index === sortedSongs.length - 1) return;
    let song = sortedSongs[index];
    let i = index;
    let targetIndex = Math.min(index + amount, sortedSongs.length - 1);
    while (i < targetIndex && i < sortedSongs.length - 1) {
      sortedSongs[i] = sortedSongs[i + 1];
      i++;
    }
    sortedSongs[targetIndex] = song;
    setSortedSongs([...sortedSongs]);
    setSortTableKey(sortTableKey + 1);
  }

  const onSelect = (list) => {
    if (!list) {
      setSelectedList(undefined);
      setSortedSongs([...playlistSongs]);
      setSortTableKey(sortTableKey + 1);
      return;
    }
    setSelectedList(list);
    const listId = list.id;
    const playlistSortOrder = playlistSortOrders.find(list => list.id === listId);
    const songsIds = playlistSortOrder.songIds;
    const newSortedSongs = songsIds.map(id => playlistSongs.find(song => song.id === id));
    setSortedSongs(newSortedSongs);
    setSortTableKey(sortTableKey + 1);
  }

  const handleUpdateClick = () => {
    setShowUpdate(true);
  }

  const handleSaveClick = () => {
    setShowSave(true);
  }

  const handleDeleteClick = () => {
    setShowDelete(true);
  }

  const options = playlistSortOrders.map(list => ({id: list.id, text: list.name}));

  const DeleteSortOrderModal = () => {
    return <ModalDecisionBuilder
        title={"Delete Sort Order"}
        message={"Do you want to delete the sort order?"}
        handleAccept={async () => {
          await playlistService.deletePlaylistSortOrder(selectedList.id);
          setTimeout(loadPlaylistSortOrders, 500);
          setShowDelete(false);
        }}
        onCancel={() => {
          setShowDelete(false);
        }}
        handleClose={() => {
          setShowDelete(false);
        }}
    />
  }


  const SaveSortOrderModal = () => {
    const [name, setName] = useState("");
    return <ModalDecisionBuilder
        title={(showSave ? "Save" : "Update") + " Sort Order"}
        message={"Do you want to " + (showSave ? "save" : "update") + " the sort order?"}
        content={showUpdate ? "Do you want to update the sort order?" :
            <StyledTextField
                placeholder={"Enter name"} inputProps={{style: {color: "white"}}} value={name} onChange={(e) => {
              setName(e.target.value);
            }
            }
            ></StyledTextField>}
        handleAccept={() => {
          if (showSave) {
            if (name.trim().length === 0) {
              return;
            }
            if (playlistSortOrders.find(list => list.name === name)) {
              return;
            }
            dispatch(playlistActions.savePlaylistSortOrder(playlistId, name, sortedSongs.map(song => song.id)));
          } else if (showUpdate) {
            dispatch(playlistActions.updatePlaylistSortOrder(selectedList.id, sortedSongs.map(song => song.id)));
          }
          setTimeout(loadPlaylistSortOrders, 500);
          setShowSave(false);
          setShowUpdate(false);
        }}

        onCancel={() => {
          setShowSave(false);
          setShowUpdate(false);
        }}
        handleClose={() => {
          setShowSave(false);
          setShowUpdate(false);
        }}
    />
  }

  const getSongIndex = (songId) => {
    return sortedSongs.findIndex(song => song.id === songId);
  }

  return <Container style={{height: "100%", maxHeight: "100%", margin: 10, marginTop: 10, padding: 0}}>
    <Row>
      <Col xs={"auto"}>
        <DropdownFilter selectedTextColor={uiConstants.CASSETTE_COLOR} onSelect={onSelect}
            selectedId={selectedList}
            placeholder="Sorted lists"
            options={options} />
        </Col>
      <Col xs={"auto"}>
      <Col style={{width: 50, paddingTop: 10}} className="align-items-center d-flex justify-content-center">
                     {selectedList && <IconHover style={{marginRight: 0}} color='white' opacity=".3" icon="trash-alt"
                         onClick={handleDeleteClick} data-tip="Delete" />}
      </Col>
      </Col>
      <Col xs={"auto"}>
        <Button style={{width: 100, background: uiConstants.CASSETTE_COLOR}} onClick={handleUpdateClick}
            disabled={!selectedList}>Update</Button>
      </Col>
      <Col xs={"auto"}>
        <Button style={{width: 100, background: uiConstants.CASSETTE_COLOR}} onClick={handleSaveClick}>Save</Button>
      </Col>
    </Row>
    <Row>
      <Col>
        <SortTable
            key={`songsSortTable${sortTableKey}`}
            tableName={"sort_songs_table"}
            noCache={true}
            gutter={18}
            rows={sortedSongs}
            columns={[
              {
                fixed: 30,
                noTooltip: true,
                rowRenderer: row => {
                  let singleClickTimer;
                  const songIndex = getSongIndex(row.id);
                  const handleClick = (doubleClick, arrow) => {
                    let color = "green"
                    row.goingUp = true;
                    arrow.style.color = color;
                    setTimeout(() => {
                      arrow.style.color = "white";
                    }, 500);
                    if (!doubleClick) {
                      singleClickTimer = setTimeout(() => {
                        moveSongUp(songIndex, 1);
                      }, 250);
                    } else {
                      clearTimeout(singleClickTimer);
                      moveSongUp(songIndex, 10);
                    }
                  }
                  return <div>
                           {songIndex !== 0 &&
                               <IconArrowUp
                                   onDoubleClick={e => {
                                     handleClick(true, e.target)
                                   }}
                                   onClick={e => {
                                     handleClick(false, e.target);
                                   }}
                               />}
                          </div>
                }
              },
              {
                fixed: 30,
                noTooltip: true,
                rowRenderer: row => {
                  const songIndex = getSongIndex(row.id);
                  return <ClickableInput value={songIndex + 1}
                      sortedSongs={sortedSongs}
                      onChange={(value) => {
                        let index = songIndex;
                        if (value - 1 < index) {
                          moveSongUp(index, index - (value - 1));
                        } else if (value > index) {
                          moveSongDown(index, (value - 1) - index);
                        }
                      }}></ClickableInput>
                }
              },

              {
                fixed: 30,
                noTooltip: true,
                rowRenderer: row => {
                  let singleClickTimer;
                  const songIndex = getSongIndex(row.id);
                  const handleClick = (doubleClick, arrow) => {
                    let color = "red"
                    row.goingUp = true;
                    arrow.style.color = color;
                    setTimeout(() => {
                      arrow.style.color = "white";
                    }, 500);

                    if (!doubleClick) {
                      singleClickTimer = setTimeout(() => {
                        moveSongDown(songIndex, 1);
                      }, 250);
                    } else {
                      clearTimeout(singleClickTimer);
                      moveSongDown(songIndex, 10);
                    }
                  }
                  return <div>
        {songIndex !== sortedSongs.length - 1 && <IconArrowDown
            onDoubleClick={e => {
              handleClick(true, e.target)
            }}
            onClick={e => {
              handleClick(false, e.target);
            }}
        />}</div>
                }
              },
              {
                fixed: 30,
                noTooltip:
                    true, rowRenderer:
                    () => (
                        <div className="onRowHover">
                                                    <IconPlay onClick={e => {
                                                      e.stopPropagation();
                                                      //       this.playSong(row);
                                                    }} />
                                                  </div>
                    )
              },
              {
                fixed: 20,
                noTooltip: true,
                rowRenderer: () =>
                    <IconInfo color={'grey'} opacity={.4} onClick={e => {
                      e.stopPropagation();
                      //       this.handleInfo(row)
                    }} />
              }
              ,
              {
                title: 'Track',
                field:
                    'title',
                percent:
                    25,
              },
              {
                title: 'Artist',
                field:
                    'artist.name',
                percent:
                    20
              },
              {
                title: 'Album',
                field:
                    'album.name',
                percent:
                    20
              },
              {
                title: 'BPM',
                field:
                    'bpm',
                isNumber:
                    true,
                fixed:
                    70,
                collapse:
                    800,
                dim:
                    true
              },
              {
                title: 'Year',
                field:
                    'yearReleased',
                isNumber:
                    true,
                fixed:
                    70,
                collapse:
                    900,
                dim:
                    true
              },
              {
                title: 'length',
                field: 'trackLength',
                isNumber: true,
                fixed: 70,
                collapse: 1000,
                dim: true,
                rowRenderer: row => (defineTrackLength(row.trackLength))
              }
            ]}
        />
</Col>
</Row>
    {(showSave || showUpdate) && <SaveSortOrderModal></SaveSortOrderModal>}
    {(showDelete) && <DeleteSortOrderModal></DeleteSortOrderModal>}
</Container>

}