import React from 'react'
import { connect } from "react-redux";
import { DropdownFilter } from './Standard/DropdownFilter';
import { uiActions } from '_actions';
import { uiConstants } from "../_constants";

const ClientDropdownFilter = ({clientSelect, activeClientId, withMaster, clients, withoutCurrent, dispatch}) => {
  if (withMaster) {
    clients = [{id: 0, name: 'Master'}, ...clients]
  }

  const onSelect = (client) => {
    if (!withoutCurrent) {
      uiActions.setClientId(dispatch, client ? client.id : -1);
    }
    clientSelect(client);
  }
  const clientOptionsSorted = clients.sort((a, b) => a.name.localeCompare(b.name)).map(c => ({id: c.id, text: c.name}));
  return <DropdownFilter selectedTextColor={uiConstants.CASSETTE_COLOR} onSelect={onSelect}
      selectedId={withoutCurrent ? undefined : activeClientId}
      placeholder="Client"
      options={clientOptionsSorted} />;
}

const mapStateToProps = state => ({
  clients: state.client.client,
  activeClientId: state.ui.clientId
});

const _ClientDropdownFilter = connect(mapStateToProps)(ClientDropdownFilter);
export { _ClientDropdownFilter as ClientDropdownFilter };
