import { createCanBoundTo } from "@casl/react";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { clientActions, userActions } from "_actions";
import {
  CreateClientUser,
  CreateUser,
  FilterSection,
  PrimaryButton,
  RowSpaced,
  SearchFilter,
  SectionGutter,
  SectionHeader,
  SectionTitle
} from "_components";
import { SortTable } from '_components/Standard';
import { getAbility, Matches } from "_helpers";
import { StandardMoment, TabControl, theme } from "_styles";

let ability, Can;

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreateUser: false,
      showAddClientUsers: false,
      search: "",
      typeUser: "users",
      user: undefined,
      showBlocked: false,
      filterClientId: -1
    };

    ability = getAbility();
    Can = createCanBoundTo(ability);
  }

  componentDidMount() {
    userActions.getUsers(this.props.dispatch);
  }

  handleSearch = filterText => {
    this.setState({search: filterText});
  };

  handleSwitchTable = e => {
    this.setState({typeUser: e}, () => {
      if (e === "users") {
        userActions.getUsers(this.props.dispatch);
      } else {
        this.props.dispatch(clientActions.getUsers());
      }
    });
  };

  handleEdit = info => {
    if (getAbility().can("edit", "internal user")) {
      this.setState({user: info, showCreateUser: true});
    }
  };

  handleEditClientUser = info => {
    this.setState({user: info, showAddClientUsers: true});
  };

  closeCreate = () => {
    this.setState({user: undefined, showCreateUser: false, showAddClientUsers: false});
  }

  successUser = () => {
    this.closeCreate();
    userActions.getUsers(this.props.dispatch);
  };

  successClientUser = () => {
    this.closeCreate();
    this.props.dispatch(clientActions.getUsers());
  };

  handleAddClick = () => {
    if (this.state.typeUser === "users") {
      this.setState({showCreateUser: true});
    } else {
      this.setState({showAddClientUsers: true});
    }
  };

  filterClient = (client) => {
    this.setState({filterClientId: client ? client.id : -1});
  }

  render() {
    const {showCreateUser, showAddClientUsers, typeUser} = this.state;
    const {users = [], clientusers = [], loading} = this.props;

    const isUsersView = typeUser === "users";

    let data = Matches(isUsersView ? users : clientusers, this.state.search, [
      "firstName",
      "lastName",
      "email"
    ]);

    if (!isUsersView && this.state.filterClientId !== -1) {
      data = data.filter(l => l.clientExternal && l.clientExternal.id === this.state.filterClientId);
    }

    return (
        <ThemeProvider theme={theme}>
                <>
                    {showCreateUser && (
                        <CreateUser user={this.state.user} onBack={this.closeCreate} onCreate={this.successUser} />
                    )}
                  {showAddClientUsers && (
                      <CreateClientUser user={this.state.user} locations={this.props.locations}
                          onArchive={this.successClientUser} onBack={this.closeCreate}
                          onCreate={this.successClientUser} />
                  )}

                  <SectionHeader>
                        <RowSpaced>
                            <SectionTitle>Users</SectionTitle>
                          {isUsersView ? (
                              <Can I="add" a="internal user">
                                    <PrimaryButton onClick={this.handleAddClick}>
                                        + Add User
                                    </PrimaryButton>
                                </Can>
                          ) : (
                              <Can I="add" a="client user">
                                    <PrimaryButton onClick={this.handleAddClick}>
                                        + Add Client User
                                    </PrimaryButton>
                                </Can>
                          )}
                        </RowSpaced>

                        <FilterSection>
                            <TabControl
                                key={`user-control-tab`}
                                controls={[
                                  {
                                    title: "Users",
                                    onClick: () => this.handleSwitchTable("users")
                                  },
                                  {
                                    title: "Client Users",
                                    onClick: () => this.handleSwitchTable("clientusers")
                                  }
                                ]}
                            />
                        </FilterSection>

                        <FilterSection>
                            <SearchFilter onChange={this.handleSearch} value={this.state.search} />
                          {/*{isUsersView && (!this.state.showBlocked ? (*/}
                          {/*    <Active style={{width: 200}}*/}
                          {/*            onClick={() => this.setState({showBlocked: true})}>*/}
                          {/*        Show blocked*/}
                          {/*    </Active>*/}
                          {/*) : (*/}
                          {/*    <Inactive style={{width: 200}}*/}
                          {/*              onClick={() => this.setState({showBlocked: false})}>*/}
                          {/*        Hide Blocked*/}
                          {/*    </Inactive>))}*/}
                          {/*{!isUsersView && <ClientDropdownFilter clientSelect={this.filterClient}/>}*/}
                        </FilterSection>
                    </SectionHeader>

                  {isUsersView ? (
                      <SortTable
                          tableName={'users'}
                          loading={loading}
                          rows={this.state.showBlocked ? data : data.filter(u => !u.blocked)}
                          gutter={SectionGutter}
                          disableColumnFiltering={true}
                          onRowClick={this.handleEdit}
                          columns={[
                            {
                              percent: 1, field: 'firstName', title: 'user', rowRenderer: row => (
                                  `${row.firstName} ${row.lastName} ${row.blocked ? ' (blocked)' : ''}`
                              )
                            },
                            {percent: 1, field: 'email', title: 'email'},
                            {percent: 1, field: 'userType', title: 'type'},
                            {percent: 1, field: 'clientsAssigned.length', title: 'clients', isNumber: true},
                            {
                              percent: 1,
                              field: 'lastLogged',
                              title: 'last login (Local)',
                              isNumber: true,
                              dim: true,
                              rowRenderer: row => {
                                if (row.lastLogged !== undefined) return (
                                    <StandardMoment>{new Date(row.lastLogged)}</StandardMoment>
                                );

                                return 'Never';
                              }
                            }
                          ]}
                      />
                  ) : (
                      <SortTable
                          tableName={'clientusers'}
                          loading={loading}
                          rows={data}
                          gutter={SectionGutter}
                          onRowClick={this.handleEditClientUser}
                          columns={[
                            {
                              percent: 1, field: 'firstName', title: 'user', rowRenderer: row => (
                                  `${row.firstName} ${row.lastName}`
                              )
                            },
                            {percent: 1, field: 'email', title: 'email'},
                            {percent: 1, field: 'phoneNumber', title: 'phone'},
                            {percent: 1, field: 'clientExternal.name', title: 'client'},
                            {
                              percent: 1,
                              field: 'lastLogged',
                              title: 'last login (Local)',
                              isNumber: true,
                              dim: true,
                              rowRenderer: row => {
                                if (row.lastLogged !== undefined) return (
                                    <StandardMoment>{new Date(row.lastLogged)}</StandardMoment>
                                );

                                return 'Never';
                              }
                            }
                          ]}
                      />
                  )}
                </>
            </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  alert: state.alert,
  loading: state.users.loading,
  users: state.users.users,
  clientusers: state.client.clientUsers
});

const connectedUsers = withRouter(connect(mapStateToProps)(Users));
export { connectedUsers as Users };

