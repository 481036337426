import React from 'react'
import { DropdownFilter } from './Standard/DropdownFilter';
import { uiConstants } from "../_constants";

export const PlaylistsDropdownFilter = ({playlistSelected, activePlaylistId, playlists}) => {
  const options = playlists.map(p => ({id: p.id, text: p.name}));

  const onSelect = (playlist) => {
    playlistSelected(playlist);
  }

  return <DropdownFilter selectedTextColor={uiConstants.CASSETTE_COLOR} onSelect={onSelect} selectedId={activePlaylistId}
      placeholder="Playlist" options={options} />;
}
