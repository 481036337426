import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import { SearchFilter } from './SearchFilter';
import { Col, Container, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { truncateString } from "../Utils";
import { uiConstants } from "../../_constants";

export const ReactTooltipStyled = styled(ReactTooltip)`
    background: white !important;
    color: black !important;
    border: 1px solid grey !important;

    .place-left::after {
        border-left: 8px solid grey !important;
    }

    .place-right::after {
        border-right: 8px solid grey !important;
    }

    .place-top::after {
        border-top: 8px solid grey !important;
    }

    .place-bottom::after {
        border-bottom: 8px solid grey !important;
    }


`;

const List = styled.div`
    margin-top: 8px;
    overflow: auto;
    max-height: 385px;
`;

const ListItem = styled.div`
    display: flex;
    height: 42px;
    align-items: center;
    color: ${props => props.selected ? 'white' : 'inherit'};
    cursor: pointer;

    &:hover {
        color: white;
    }
`;

const ListItemText = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
`;

const IconContainer = styled.div`
    width: 20px;
    margin-right: 8px;
`;

const SelectIcon = styled(FontAwesomeIcon)`
    color: ${uiConstants.CASSETTE_COLOR};
`;

const ContainerStyled = styled(Container)`
    padding: 0px;
    color: #BBB;
    user-select: none;
`;

const SelectionList = ({items, selectedIds, onListItemClicked}) => {
  return <List>
        {items.map((item, index) => {
          const selected = selectedIds.includes(item.id);
          return <ListItem key={item.id} selected={selected} data-index={index} onClick={onListItemClicked}>
                <IconContainer>
                    {selected && (<SelectIcon icon={['fal', 'check']} fixedWidth />)}
                </IconContainer>
                <ListItemText title={item.text}>
                    {truncateString(item.text, 25)}
                </ListItemText>
            </ListItem>
        })}
    </List>

}

export const SelectionContextMenu = ({items, selectedIds, onListItemClicked, songsData, maxItems = -1}) => {
  const [filteredItems, setFilteredItems] = useState(maxItems > 0 ? items.slice(0, maxItems) : items);
  const [filter, setFilter] = useState('');

  const handleFilter = text => {
    setFilter(text);
    const query = text.toLowerCase();
    let filteredItems = items.filter(item => item.text.toLowerCase().indexOf(query) !== -1);
    if (maxItems > 0) {
      filteredItems = filteredItems.slice(0, maxItems);
    }
    setFilteredItems(filteredItems);
  }

  const onClick = e => {
    const index = e.currentTarget.dataset.index;
    onListItemClicked(filteredItems[index]);
  }

  return (
      <ContainerStyled>
            <Row>
                <Col style={{maxHeight: 600, overflowY: "scroll"}}>
                    <Row>
                        <Col xs={10}>
                            <SearchFilter onChange={handleFilter} value={filter} />
                        </Col>
                      {songsData &&
                          <Col xs={2} style={{display: "flex"}} className="align-items-center">
                                <Row data-tip data-for='songs_list'>
                                    <Col>{songsData.length} selected
                                        <ReactTooltipStyled effect='solid' place="bottom" type='light' id='songs_list'>
                                            {songsData.map(song => <>{song.title}<br /></>)}
                                        </ReactTooltipStyled>
                                    </Col>
                                </Row>
                            </Col>
                      }
                    </Row>
                    <Row>
                        <Col>
                            <SelectionList items={filteredItems} selectedIds={selectedIds} onListItemClicked={onClick} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ContainerStyled>

  )
}