import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {
    AddToMultipleComponent,
    SectionGutter,
    SearchFilter,
    SectionHeader,
    SectionTitle,
    ModalDecisionBuilder,
    IconHover,
    IconCheck,
    IconPlus,
    durationToString
} from "_components";
import {getPlaylistLookupView, getPlaylists} from "_reducers/playlist.reducer";
import {SortTable, IconPlay} from '_components/Standard';
import {alertActions, playlistActions, playerActions, songsActions} from "_actions";
import {Matches} from '_helpers';
import {StandardMoment} from "_styles";
import JSONTree from 'react-json-tree';
import get from 'lodash.get';
import {Button, Col, Row} from "react-bootstrap";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import {songsService} from "../_services";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { uiConstants } from "../_constants";

const SongName = styled.div`
    color: white;
    margin-left: 12px;
`;

const PlaylistLink = styled.a`
    color: white;

    &:hover {
        color: white;
    }
`;

const theme = {
    base00: 'transparent',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633'
};


class SongDetail extends React.Component {
    state = {
        search: '',
        showConfirmArchiving: false,
        explicitTypeChanged: false,
        selectedExplicitType: this.props.song.songExplicitType
    }

    componentDidMount() {
        songsActions.getSongPlaylists(this.props.dispatch, this.props.id);
    }

    handleSearch = filterText => {
        this.setState({search: filterText});
    };

    openPlaylist = playlist => {
        this.props.history.push(`/playlist/${playlist.id}`);
    }

    pinPlaylist = playlistId => {
        if (this.props.activePlaylistId === playlistId) {
            this.props.dispatch(playlistActions.setActivePlaylistId(-1));
        } else {
            playlistActions.getPlaylist(this.props.dispatch, playlistId);
            this.props.dispatch(playlistActions.setActivePlaylistId(playlistId));

        }
    };

    saveSongToMultiple = async (playlists, hide) => {
        const {dispatch} = this.props;
        const songIds = [this.props.id];

        // TODO rewire hide()
        hide();

        try {
            const results = playlists.map(pl => playlistActions.addSongs(dispatch, pl.id, songIds));
            await Promise.all(results)
            alertActions.notificationSuccess(dispatch, "Song Added", `${this.props.song.title} added to ${playlists.length} playlists`);
        } catch (e) {
            alertActions.notificationError(dispatch, "Error", e.toString());
        }
    }


    closeDeleteConfirmationDialog = () => {
        this.setState({showConfirmArchiving: false});
    }

    doArchiving = async () => {
        const {dispatch, song} = this.props;
        const songId = song.id;
        const result = await songsActions.archive(dispatch, songId);
        if (result) {
            alertActions.notificationSuccess(dispatch, "Song archived", "");
            songsActions.getSongs(dispatch);
            this.props.history.goBack();
        }

    }

    handleAddSong = () => {
        playlistActions.addSongs(this.props.dispatch, this.props.activePlaylistId, [this.props.song.id])
    };

    handleRemoveSong = () => {
        playlistActions.deleteSongs(this.props.dispatch, this.props.activePlaylistId, [this.props.song.id])
    };

    render() {
        const {playlists, loading, song} = this.props;

        let data = Matches(playlists, this.state.search, ["name"]);
        const changeExplicitType = (explicitType) => {
            song.songExplicitType = explicitType;
            this.setState({explicitTypeChanged: true, selectedExplicitType: explicitType})
        };
        if (song.dateAdded) {
            let dateAdded = song["dateAdded"];
            let dateModified = song["dateModified"];
            let trackLength = song["trackLength"];
            let formattedDateAdded = moment(dateAdded).format("MM/DD/YYYY hh:mm");
            let formattedDateModified = dateModified ? moment(dateModified).format("MM/DD/YYYY hh:mm") : undefined;
            let formattedTrackLength = durationToString(trackLength);
            song["dateAdded"] = formattedDateAdded;
            song["dateModified"] = formattedDateModified;
            song["trackLength"] = formattedTrackLength;
        }
        return (
            <>
                <SectionHeader>
                    <Row>
                        <Col style={{padding: 0}}>
                            <SectionTitle>Song</SectionTitle>
                        </Col>
                        <Col className="align-items-right d-flex justify-content-end">
                            <IconHover style={{marginRight: 0}} color='white' opacity=".3" icon="trash-alt"
                                       onClick={() => {
                                           this.setState({showConfirmArchiving: true})
                                       }} data-tip="Archive"/>
                        </Col>
                    </Row>

                    <Row>
                        <IconPlay color='white' onClick={e => {
                            e.stopPropagation();
                            playerActions.play(this.props.dispatch, song.id, song.title, song.artist.name, song.artworkUrl)
                        }}/>
                        <SongName>{song.title} - {get(song, 'artist.name', '')}</SongName> &nbsp;

                        {this.props.activePlaylistId !== -1 && (this.props.activeSongs[song.id] ? (
                            <IconCheck color={uiConstants.CASSETTE_COLOR} onClick={e => {
                                e.stopPropagation();
                                this.handleRemoveSong()
                            }}/>
                        ) : (
                            <IconPlus color='white' onClick={e => {
                                e.stopPropagation();
                                this.handleAddSong()
                            }}/>
                        ))}
                    </Row>

                    <Row style={{marginBottom: 50}}>
                        <SearchFilter onChange={this.handleSearch} value={this.state.search}/>
                    </Row>
                    <Row style={{marginBottom: 25}}>
                        <AddToMultipleComponent
                            songsData={[song]}
                            onSave={this.saveSongToMultiple}
                            playlists={this.props.allPlaylists}/>
                    </Row>
                </SectionHeader>
                {this.state.showConfirmArchiving && (
                    <ModalDecisionBuilder
                        title={"Are you sure you want to archive this song?"}
                        handleClose={this.closeDeleteConfirmationDialog}
                        handleAccept={this.doArchiving}
                    />
                )}
                <Row>
                    <Col style={{padding: 0}}>
                        <SortTable
                            tableName={'song_detail_playlist'}
                            style={{flex: 1}}
                            loading={loading}
                            gutter={SectionGutter}
                            rows={data}
                            columns={[
                                {
                                    fixed: 25, field: 'playlist', title: '', rowRenderer: row => {
                                        const pinned = this.props.activePlaylistId === row.id;
                                        return (
                                            <div className={!pinned && "onRowHover"}>
                                                <FontAwesomeIcon icon="thumbtack" size="sm"
                                                                 style={{
                                                                     color: pinned ? uiConstants.CASSETTE_COLOR : "gray",
                                                                     cursor: "pointer"
                                                                 }}
                                                                 onClick={(e) => {
                                                                     e.stopPropagation();
                                                                     this.pinPlaylist(row.id);
                                                                 }}
                                                />
                                            </div>
                                        )

                                    }
                                },
                                {percent: 1, field: 'clientName', title: 'client'},
                                {
                                    percent: 1, field: 'name', title: 'playlist', rowRenderer: row => {
                                        return <div>
                                            <PlaylistLink href="#"
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              this.props.history.push(`/playlist/${row.id}`)
                                                          }}> {row.name}</PlaylistLink>
                                        </div>
                                    }
                                },
                                {
                                    percent: 1,
                                    field: 'dateAdded',
                                    isNumber: true,
                                    title: 'Added (Local)',
                                    rowRenderer: row => (
                                        <StandardMoment>{row.dateAdded}</StandardMoment>
                                    )
                                }
                            ]}
                        />
                    </Col>
                    <Col style={{padding: 0}}>
                        <Row style={{flex: 1, borderBottom: "2px solid #4A494E", marginTop: 10, paddingBottom: 20}}>
                            <FormControl style={{color: "white"}}>
                                <FormLabel id="demo-radio-buttons-group-label" style={{color: "#4A494E"}}>EXPLICIT
                                    INFO</FormLabel>
                                <RadioGroup row>
                                    <FormControlLabel style={{marginBottom: 0}} value="no"
                                                      control={<Radio
                                                          checked={this.props.song.songExplicitType === "CLEAN"}
                                                          onChange={() => changeExplicitType("CLEAN")}
                                                          style={{color: uiConstants.CASSETTE_COLOR}}/>} label="Clean"/>
                                    <FormControlLabel style={{marginBottom: 0}} value="adult" control={<Radio
                                        checked={this.props.song.songExplicitType === "ADULT"}
                                        onChange={() => changeExplicitType("ADULT")} style={{color: uiConstants.CASSETTE_COLOR}}/>}
                                                      label="Adult"/>
                                    <FormControlLabel style={{marginBottom: 0}} value="explicit" control={<Radio
                                        checked={this.props.song.songExplicitType === "EXPLICIT"}
                                        onChange={() => changeExplicitType("EXPLICIT")} style={{color: uiConstants.CASSETTE_COLOR}}/>}
                                                      label="Explicit"/>
                                    <Button style={{background: uiConstants.CASSETTE_COLOR}} disabled={!this.state.explicitTypeChanged}
                                            onClick={this.updateButtonClicked.bind(this)}>Update</Button>
                                </RadioGroup>
                            </FormControl>
                        </Row>
                        <Row style={{flex: 1, paddingTop: 20}}>

                            <FormLabel id="demo-radio-buttons-group-label" style={{color: "#4A494E"}}>SONG
                                METADATA</FormLabel>
                        </Row>
                        <Row>
                            <JSONTree data={song} theme={theme} invertTheme={false}/>
                        </Row>

                    </Col>
                </Row>
            </>
        );
    }

    async updateButtonClicked() {
        this.setState({explicitTypeChanged: false});
        await songsService.updateSongExplicitType(this.props.song.id, this.state.selectedExplicitType);
        alertActions.notificationSuccess(this.props.dispatch, "Song updated", "Song updated successfully");
    }
}

const mapStateToProps = (state, props) => {
    const id = parseInt(props.id || props.match.params.id);
    const activePlaylistId = state.playlists.activePlaylistId;
    return {
        id,
        loading: state.songs.songPlaylists.loading,
        playlists: state.songs.songPlaylists.playlists,
        activePlaylistId,
        activeSongs: getPlaylistLookupView(state, activePlaylistId),
        allPlaylists: getPlaylists(state),
        song: (state.songs.songs || []).find(song => song.id === id) || {}
    }
}

const connectedSongDetail = withRouter(connect(mapStateToProps)(SongDetail));
export {connectedSongDetail as SongDetail};

