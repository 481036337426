import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {clientActions, playlistActions, alertActions} from "_actions";
import {Tags} from "./Tags";
import {
    DefaultLabel,
    DefaultColumnContainer,
    DefaultFormControl,
    CreationForms,
    Helper,
    theme
} from "_styles";
import Select, {Creatable} from "react-select";
import {getReactSelectList} from "_helpers";
import image from "_assets/images/brand-wong.jpg";
import {getPlaylists, playlists} from "../_reducers/playlist.reducer";
import {playlistService} from "../_services";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import { uiConstants } from "../_constants";

const FormContainerWithHelper = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
  margin-top: 10px;
`;

const FormLabel = styled(DefaultLabel)`
  color: ${props => props.theme.formlabel};
  font-size: 14px;
`;

const Form = styled(DefaultFormControl)`
  margin-top: 5px;
`;

const TypesContainer = styled(DefaultColumnContainer)`
  width: 100%;
  margin-top: 20px;
  align-items: flex-start;
`;

const RSelect = styled(Select)`
  width: 100%;
  margin-top: 10px;
  color: black;
`;

class CreatePlaylist extends React.Component {
    state = {
        name: "",
        client: "",
        description: "",
        tags: [],
        creating: false,
        availableGroups: [],
        playlistTags: [],
        selectedExplicitType: "CLEAN"
    };

    componentDidMount() {
        clientActions.getAllClients(this.props.dispatch);
        const masterPlaylistsGroups = [...new Set(this.props.playlists.filter(p => !p.client && p.playlistGroup).map(p => p.playlistGroup))];
        this.setState({availableGroups: masterPlaylistsGroups});
        playlistService.getPlaylistTags(this.props.dispatch).then(playlistTags => {
            this.setState({playlistTags});
        });
    }

    handleChange = e => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

    handleClient = client => {
        const playlistsGroups = client.value ? [...new Set(this.props.playlists.filter(p => p.client && p.client.id === client.value && p.playlistGroup).map(p => p.playlistGroup))] :
            [...new Set(this.props.playlists.filter(p => !p.client && p.playlistGroup).map(p => p.playlistGroup))];
        this.setState({client: client, availableGroups: playlistsGroups, group: null});
    };

    handleSelect = e => {
        let vr = e.variable;
        this.setState({[vr]: e});
    };

    handleGroup = e => {
        this.setState({group: e ? e.value : undefined});
    };

    handleTagSelection = e => {
        this.setState({tags: e.map(tag => tag.value)});
    };

    changeExplicitType = (explicitType) => {
        this.setState({selectedExplicitType: explicitType})
    };


    handleSubmit = async () => {
        const {name, client, description, tags, group} = this.state;
        if (name !== "" && client !== "" && tags.length !== 0) {
            this.setState({creating: true, errorString: ''})
            const clientId = client.value === 0 ? {} : {client: {id: client.value}};
            try {
                const playlist = await playlistActions.createPlaylist(this.props.dispatch, {
                    name,
                    ...clientId,
                    description,
                    allowedExplicitType: this.state.selectedExplicitType,
                    tags,
                    playlistGroup: group
                });

                alertActions.notificationSuccess(this.props.dispatch, 'Playlist Created', name);
                this.props.onCreated(playlist);
            } catch (error) {
                this.setState({
                    errorString: error.toString()
                })
            }
        } else {
            this.setState({
                errorString: "Please fill in the entire form"
            });
        }

        this.setState({creating: false})
    };

    render() {
        const {onBack, client = []} = this.props;
        const {name, description, errorString, creating} = this.state;

        let clientList = getReactSelectList(client, "client");
        clientList = [{variable: 'client', value: 0, label: 'Master Playlist'}, ...clientList];
        return (
            <ThemeProvider theme={theme}>
                <CreationForms
                    message="This will create a new playlist, please check the information entered above is correct."
                    handleBackButton={onBack}
                    handleSubmit={this.handleSubmit}
                    submitting={creating}
                    alert={errorString}
                    image={image}
                    title="Create New Playlist"
                >
                    <FormLabel>Playlist Name</FormLabel>
                    <Form
                        type="text"
                        name="name"
                        placeholder="Name of Playlist"
                        value={name}
                        onChange={this.handleChange}
                    />
                    <TypesContainer>
                        <FormLabel>Client</FormLabel>
                        <RSelect
                            options={clientList}
                            name="client"
                            onChange={this.handleClient}
                        />
                        <Helper message="Choose one client"/>
                    </TypesContainer>
                    <FormContainerWithHelper>
                        <FormLabel>Description</FormLabel>
                        <Form
                            type="text"
                            name="description"
                            placeholder="Description"
                            value={description}
                            onChange={this.handleChange}
                        />
                    </FormContainerWithHelper>
                    <FormLabel style={{marginTop: 10}}>Tags</FormLabel>
                    <Creatable
                        styles={{
                            control: styles => ({...styles, height: 50, marginTop: 10})
                        }}
                        options={this.state.playlistTags.sort().map(e => {
                            return {value: e, label: e}
                        })}
                        isMulti={true}
                        isClearable={true}
                        name="tags"
                        onChange={this.handleTagSelection}
                        value={this.state.tags.map(tag => {
                            return {value: tag, label: tag}
                        })}
                    />
                    <FormContainerWithHelper>
                        <FormLabel style={{marginTop: 10}}>Group</FormLabel>
                        <Creatable
                            styles={{
                                container: styles => ({...styles, width: '100%'}),
                                control: styles => ({...styles, height: 50, marginTop: 10})
                            }}
                            options={this.state.availableGroups.sort().map(e => {
                                return {value: e, label: e}
                            })}
                            isClearable={true}
                            name="group"
                            onChange={this.handleGroup}
                            value={{value: this.state.group, label: this.state.group}}
                        />
                    </FormContainerWithHelper>
                    <FormContainerWithHelper>
                        <FormLabel style={{marginTop: 10, marginBottom: 10}}>Explicit Allowed</FormLabel>
                        <RadioGroup row>
                            <FormControlLabel style={{marginBottom: 0, color: "white"}} value="no"
                                              control={<Radio
                                                  checked={this.state.selectedExplicitType === "CLEAN"}
                                                  onChange={() => this.changeExplicitType("CLEAN")}
                                                  style={{color: uiConstants.CASSETTE_COLOR}}/>} label="Clean"/>
                            <FormControlLabel style={{marginBottom: 0, color: "white"}} value="adult"
                                              control={<Radio
                                                  checked={this.state.selectedExplicitType === "ADULT"}
                                                  onChange={() => this.changeExplicitType("ADULT")}
                                                  style={{color: uiConstants.CASSETTE_COLOR}}/>}
                                              label="Adult"/>
                            <FormControlLabel style={{marginBottom: 0, color: "white"}} value="explicit"
                                              control={<Radio
                                                  checked={this.state.selectedExplicitType === "EXPLICIT"}
                                                  onChange={() => this.changeExplicitType("EXPLICIT")}
                                                  style={{color: uiConstants.CASSETTE_COLOR}}/>}
                                              label="Explicit"/>
                        </RadioGroup>
                    </FormContainerWithHelper>
                </CreationForms>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    users: state.users.users,
    client: state.client.client,
    playlists: getPlaylists(state)
});

const connectedCreatePlaylist = withRouter(connect(mapStateToProps)(CreatePlaylist));
export {connectedCreatePlaylist as CreatePlaylist};
