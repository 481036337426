import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import {
  DefaultButton,
  DefaultCancelButton,
  DefaultColumnContainer,
  DefaultFormControl,
  DefaultLabel,
  DefaultRowContainer,
  Overrides,
  StandardMoment,
  theme
} from "_styles";
import {
  ModalAddOverride,
  ModalAddPlaylist,
  ModalDecisionBuilder,
  ModalEventDetails,
  ModalPicker,
  ModalPickZone,
  truncateString,
} from "_components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alertActions, playlistActions, scheduleActions } from "_actions";
import moment from "moment";
import { isLoadingCalendarList } from "_reducers/playlist.reducer";
import { getPlaylistsInfo, isScheduleLoaded } from "_reducers/schedule.reducer";
import get from 'lodash.get';
import partition from 'lodash.partition';
import ReactTooltip from 'react-tooltip';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import moreImage from "_assets/images/more.png";

// must manually import the stylesheets for each plugin
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import "_styles/custom-fullcalendar.css";
import { uiConstants } from "../_constants";

// TODO maybe overkill for generating an string-id
const randomString = require("random-string");

const Container = styled(DefaultColumnContainer)`
    font-size: 16px;
    height: 100%;
    width: 100%;
    overflow: visible;
`;

const TitleContainer = styled(DefaultRowContainer)`
    justify-content: space-between;
    width: 100%;
    padding: 30px 30px 0;
`;

const LeftMenuContainer = styled(DefaultColumnContainer)`
    align-items: flex-start;
`;

const EditContainer = styled.div`
    display: flex;
    align-items: center;
`;

const TracksLabel = styled.div`
    color: grey;
    font-size: 16px;
    margin-left: 12px;
`;

const Edit = styled(FontAwesomeIcon)`
    margin-left: 20px;
    color: white;
    cursor: pointer;
`;

const Title = styled(DefaultLabel)`
    color: white;
    font-size: 32px;
    margin-bottom: 10px;
`;

const Form = styled(DefaultFormControl)`
    font-size: 32px;
    margin-bottom: 10px;
`;

const InfoContainer = styled(DefaultRowContainer)``;

const Active = styled(DefaultLabel)`
    color: white;
    cursor: default;

    &:before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: ${props => props.color};
        margin-right: 10px;
    }
`;

const Text = styled(DefaultLabel)`
    cursor: default;
    color: white;
    margin-left: 30px;
`;

const RightMenuContainer = styled(DefaultRowContainer)``;

const CancelButton = styled(DefaultCancelButton)`
    height: 40px;
`;

const SaveButton = styled(DefaultButton)`
    margin-left: 10px;
    height: 40px;
`;

const Line = styled.hr`
    background-color: ${props => props.theme.darkGray};
    height: 1px;
    width: 100%;
`;

const ContentContainer = styled(DefaultRowContainer)`
    width: 100%;
    height: 100%;
`;

const LeftContentContainer = styled(DefaultColumnContainer)`
    width: 30%;
    overflow-y: auto;
    height: 100%;
`;

const PlaylistContainer = styled(DefaultColumnContainer)`
    padding: 30px;
`;

const PlaylistText = styled(DefaultLabel)`
    color: white;
    margin-bottom: 20px;
    text-align: left;
`;

const CalendarContainer = styled(DefaultColumnContainer)`
    width: 70%;
    overflow: hidden;
    padding-right: 20px;
    margin-bottom: 40px;

    table {
        border-bottom: 1px solid #59595F;
    }

    table .fc-slats tr:nth-child(4n-1) {
        border-top: 1px solid rgba(103, 103, 103, 0.50);
    }

    table .fc-slats tr:nth-child(4n-1) .fc-axis span {
        color: rgba(103, 103, 103, 0.50);
    }

`;

const ButtonRow = styled(DefaultRowContainer)`
    background-color: #7d7d7d29;
    border-radius: 10px;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    margin-top: 20px;

    &:hover,
    &:active,
    &:focus {
        background-color: ${props => props.theme.darkGray};
    }
`;

const ButtonText = styled(DefaultLabel)`
    color: white;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
    color: white;
`;

const WarningMessage = styled.div`
    margin-top: 12px;
    color: red;
`;

const AddButton = ({children, onClick}) => (
    <ButtonRow onClick={onClick}>
        <ButtonText>{children}</ButtonText>
        <ButtonIcon icon="plus-circle" />
    </ButtonRow>
);

const Row = styled(DefaultRowContainer)`
    margin: 10px 0;
    justify-content: space-between;
    cursor: pointer;
`;

const Name = styled(DefaultLabel)`
    display: flex;
    flex-direction: row;
    color: white;

    &:before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: ${props => props.color};
        margin-right: 10px;
        text-align: center;
    }
`;

const RemoveIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.darkGray};
`;

const RandomColors = [
  "#F44336",
  "#2196F3",
  uiConstants.CASSETTE_COLOR,
  "#009688",
  "#E91E63",
  "#673AB7",
  "#FF5722",
  "#00BCD4",
  "#3F51B5",
  "#4CAF50",
  "#9C27B0",
  "#df9510",
  "#9E9E9E",
  "#03A9F4",
  "#607D8B",
  "#795548",
  "#8BC34A",
  "#FF9800"
];

function getRandomColor(index) {
  return RandomColors[index % RandomColors.length];
}

const bytesToString = (bytes) => `${parseFloat(bytes * 1e-6).toFixed(1)} MB`;

// Flatten timezone information, treating date as UTC without conversion
function floorTimezoneToString(date) {
  return moment(date).format("YYYY-MM-DD");
}

function concatDateTimeToMoment(date, timeString) {
  const startDateString = moment(date).format("MM-DD-YYYY");
  return moment(`${startDateString} ${timeString}`);
}

function concatTimeToOverride(startTime, endTime) {
  return `${concatDateTimeToMoment(moment().toDate(), startTime).format(
      "hh:mm A"
  )} -
    ${concatDateTimeToMoment(moment().toDate(), endTime).format("hh:mm A")}`;
}

// Calendar/internal event
function createEvent(id, playlistId, title, start, end, color) {
  // When the end time is resized to 12am the next day, the calendar component does not render the element.
  // To solve this, we move the `end` into the last moment of the `start` day. i.e. 23:59:59.
  // After, if the event is resized to an earlier time, we need to round the minutes back to 0 or 30
  // to undo the work from the above case.
  const momentLater = end
      .clone()
      .add(1, "s")
      .startOf("minute");
  if (momentLater.day() !== start.day()) {
    end = start.clone().endOf("day");
  } else {
    end = momentLater;
  }

  // Capture the day of the week before moving the start year
  const dayWeek = start.day();

  return {
    id: id.toString(), // calendar component requires strings for ids
    playlistId,
    title,
    start: start.toDate(), // calendar requires start to be date
    daysOfWeek: [dayWeek],
    startTime: start.format("HH:mm:ss"),
    endTime: end.format("HH:mm:ss"),
    startRecur: start.year(startDateYear).toDate(),
    color: color === "#46464540" ? "#46464540" : color,
    borderColor: "transparent", //color === "#46464540" ? "white" : color,
    editable: true,
    startMoment: start,
    endMoment: end
  };
}

const startDateYear = 1970;

const KeyCodeControl = 17;
const KeyCodeAlt = 18;

function removeFromArray(index, array) {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

function id() {
  // Calendar control requires strings for ids
  return randomString({length: 10});
}

// Timespan is compared as [Start, end), or `start` inclusive, `end` exclusive
function haveExistingEventForTimespan(
    events,
    start /*moment*/,
    end /*moment*/,
    ignoreId = undefined
) {
  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    if (event.id === ignoreId || event.override || event.insert) continue;

    event.startMoment = concatDateTimeToMoment(moment().day(event.daysOfWeek[0]).toDate(), event.startTime);
    event.endMoment = concatDateTimeToMoment(moment().day(event.daysOfWeek[0]).toDate(), event.endTime);

    if (start >= event.startMoment && start < event.endMoment) return true; // Timespan starts inside event
    if (end > event.startMoment && end <= event.endMoment) return true; // Timespan ends inside event
    if (start <= event.startMoment && end >= event.endMoment) return true; // Timespan covers event
  }
  return false;
}

const closestNextEventMinDiff = (events, start) => {
  let closestDiff = 1500000000;
  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    if (event.override || event.insert) continue;
    const startMoment = concatDateTimeToMoment(moment().day(event.daysOfWeek[0]).toDate(), event.startTime);
    if (startMoment < start) continue;
    const diff = Math.abs(startMoment.diff(start));
    if (diff < closestDiff) {
      closestDiff = diff;
    }
  }
  return closestDiff / 1000 / 60;
}

const defineUpdateText = updated => {
  if (updated === undefined) return "Never updated";

  let date = moment(updated);
  let currentDate = moment();

  let difference = currentDate.diff(date, "days");
  if (difference === 0) return "Updated Today";
  if (difference === 1) return "Updated Yesterday";
  if (difference <= 7) return `Updated ${difference} days ago`;
  return <div>Last updated <StandardMoment>{date}</StandardMoment></div>;
};


const defineClientText = client => client ? client.name : "No client associated";

const defineZonesText = zones => {
  if (zones === undefined) return "No zones";
  if (zones.length === 0) return "No active zones";
  if (zones.length === 1) return "Active 1 zone";
  return `Active ${zones.length} zones`;
};

function getDateForOffset(timeZoneOffset) {
  let offset = parseInt(timeZoneOffset.substring(0, 3));
  var localOffset = new Date().getTimezoneOffset() / 60;
  var date = new Date();
  var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours() + localOffset + offset, date.getUTCMinutes(), date.getUTCSeconds());
  let dateWithOffset = new Date(now_utc);
  return dateWithOffset;
}

class CalendarView extends React.Component {
  calendarComponentRef = React.createRef();

  state = {
    // Events used by the calendar control, mixed with user-data to track with event
    events: [],

    // Playlists from props
    playlists: [],
    zonesPick: [],
    zonePickName: "time zone",

    // Name of the schedule
    name: "",

    calendarWeekends: true,
    showModal: false,
    clickEvent: "",
    altIsPressed: false,
    showAddPlaylist: false,
    showDeletePlaylist: false,
    showPickZone: false,
    showCancelDecision: false,
    showEventDetails: false,
    showAddOverride: false,
    showAddInsert: false,
    eventDetails: "",
    isEditingName: false,
    isEditingDetails: false,
    deletePlaylistId: "",
    showDuplicateConfirmation: false,
    showArchiveConfirmation: false,
    showSaveConfirmation: false,
    now: new Date(),

  };

  componentDidMount() {
    this.props.dispatch(playlistActions.getCalendarView(this.props.id));
    scheduleActions.getSchedule(this.props.dispatch, this.props.id);

    document.addEventListener("keydown", this.keyPress, false);
    document.addEventListener("keyup", this.keyUp, false);

    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".event-playlist"
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
    document.removeEventListener("keyup", this.keyUp);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isReady && this.props.isReady) {
      // TODO isReady does not mean the data was succesfully loaded
      this.loadSchedule();
    }
  }

  refreshInfo = () => {
    scheduleActions.getPlaylistsInfo(this.props.dispatch, this.state.playlists.map(pl => pl.id));
  }

  handleKeyEvent = (event, pressed) => {
    if (event.keyCode === KeyCodeControl || event.keyCode === KeyCodeAlt) {
      this.setState(() => ({altIsPressed: pressed}));
    }
  };

  keyPress = event => this.handleKeyEvent(event, true);
  keyUp = event => this.handleKeyEvent(event, false);

  //
  // Schedules load & save operations
  //

  // Translate the redux server data to internal calendar data
  loadSchedule = () => {
    const schedule = this.props.schedule;

    let events = [];

    // Add inserts
    (schedule.scheduleInserts || []).forEach(el => {
      events.push({
        id: el.id,
        title: el.playlist.name,
        start: concatDateTimeToMoment(el.startDate, el.startTime).toDate(),
        sTime: el.startTime,
        eTime: el.endTime,
        end: concatDateTimeToMoment(el.endDate, el.endTime)
            .add(1, "d")
            .toDate(),
        playlistId: el.playlist.id,
        allDay: true,
        color: "green",
        backgroundColor: "#464645",
        editable: false,
        insert: true,
        probability: el.percentage,
        priority: el.priority,
        weekly: el.weekly,
        daysOfTheWeek: el.daysOfTheWeek
      });
    });

    // Add overrides and dayparts
    schedule.dayParts.forEach(el => {
      if (el.override) {
        events.push({
          id: el.id,
          title: el.playlist.name,
          start: concatDateTimeToMoment(el.startDate, el.startTime).toDate(),
          sTime: el.startTime,
          eTime: el.endTime,
          end: concatDateTimeToMoment(el.endDate, el.endTime)
              .add(1, "d")
              .toDate(),
          playlistId: el.playlist.id,
          allDay: true,
          color: el.color,
          backgroundColor: "#464645",
          editable: false,
          override: true,
          weekly: el.weekly,
          daysOfTheWeek: el.daysOfTheWeek
        });
      } else {
        const start = moment(el.startDate)
            .year(startDateYear)
            .day(el.weekDay);
        // Standard event
        const startMoment = concatDateTimeToMoment(start, el.startTime);
        const endMoment = concatDateTimeToMoment(start, el.endTime);
        let playlistIndex = schedule.playlists.sort((p1, p2) => p1.name.localeCompare(p2.name)).findIndex(pl => pl.id === el.playlist.id);
        const eventColor = getRandomColor(playlistIndex);

        events.push(
            createEvent(
                el.id,
                el.playlist.id,
                el.playlist.name,
                startMoment,
                endMoment,
                eventColor
            )
        );
      }
    });

    let zone = (schedule.zones && schedule.zones.length >= 1) ? schedule.zones[0] : null;
    if (zone) {
      let timeZoneOffset = zone.timeZoneOffset;
      if (timeZoneOffset) {
        let dateWithOffset = getDateForOffset(timeZoneOffset);
        this.setState({now: dateWithOffset, zone: zone, zonePickName: "time zone: " + zone.name});
      }
    }
    this.setState({
      events,
      playlists: schedule.playlists,
      zonesPick: schedule.zones,
      name: schedule.name,
    }, this.refreshInfo);
  };

  attemptSaveSchedule = () => {
    this.setState({showSaveConfirmation: true});
  }

  saveSchedule = () => {
    const {events, playlists, name} = this.state;
    const {dispatch, id} = this.props;

    const [inserts, parts] = partition(events, e => e.insert);

    const dayParts = parts.filter(dayPart => !dayPart.end || dayPart.start.getTime()
        !== dayPart.end.getTime()).map(dayPart => dayPart.override ? {
      override: true,
      playlist: {id: dayPart.playlistId},
      startDate: floorTimezoneToString(dayPart.start),
      // We need to remove one day for the actual date to go through
      // All day events in the calendar are end date exclusive
      endDate: moment(dayPart.end).subtract(1, "d").format("YYYY-MM-DD"),
      startTime: moment(dayPart.start).format("HH:mm:ss"),
      endTime: moment(dayPart.end).format("HH:mm:ss"),
      weekly: dayPart.weekly,
      daysOfTheWeek: dayPart.daysOfTheWeek
    } : {
      override: false,
      playlist: {id: dayPart.playlistId},
      startTime: dayPart.startTime,
      endTime: dayPart.endTime,
      weekDay: moment().day(dayPart.daysOfWeek[0]).format("dddd").toUpperCase()
    });
    const pack = {
      name,
      playlists: playlists.map(pl => ({id: pl.id})),
      scheduleInserts: inserts.map(i => ({
        percentage: i.probability,
        priority: i.priority,
        playlist: {id: i.playlistId},
        startDate: moment(i.start).format("YYYY-MM-DD"),
        endDate: moment(i.end).subtract(1, "d").format("YYYY-MM-DD"),
        startTime: moment(i.start).format("HH:mm:ss"),
        endTime: moment(i.end).format("HH:mm:ss"),
        weekly: i.weekly,
        daysOfTheWeek: i.daysOfTheWeek
      })),
      dayParts: dayParts
    };
    dispatch(scheduleActions.update(pack, id));
    this.setState({showSaveConfirmation: false});
    this.exitSchedule();
  };

  //
  // Playlist operations
  //

  pickZone = zone => {
    this.setState({showPickZone: false});
    let timeZoneOffset = zone.timeZoneOffset;
    if (timeZoneOffset) {
      let dateWithOffset = getDateForOffset(timeZoneOffset);
      this.setState({now: dateWithOffset, zone: zone, zonePickName: "time zone: " + zone.name});
    }
  }

  addPlaylist = playlistsToAdd => {
    this.setState({showAddPlaylist: false});

    const playlist = [];
    playlistsToAdd.forEach(playlistOptions => {
      playlist.push(
          this.props.playlist.find(pl => pl.id === playlistOptions.value)
      );
    });

    this.setState({
      playlists: this.state.playlists.concat(playlist)
    }, this.refreshInfo);
  };

  deletePlaylist = () => {
    const {deletePlaylistId, playlists, events} = this.state;

    const updatePlaylists = playlists.filter(
        playlist => playlist.id !== deletePlaylistId
    );

    this.setState({
      playlists: updatePlaylists,
      events: events
          .filter(event => event.playlistId !== deletePlaylistId)
          .map(event => {
            const color = getRandomColor(
                updatePlaylists.findIndex(pl => pl.id === event.playlistId)
            );
            return {
              ...event,
              color
            };
          }),
      deletePlaylistId: "",
      showDeletePlaylist: false
    }, this.refreshInfo);
  };

  //
  // Event operations
  //

  addEvent = (id, playlistId, title, color, startMoment, endMoment) => {
    let {events} = this.state;

    if (haveExistingEventForTimespan(events, startMoment, endMoment, id))
      return false;

    // Remove existing event if found
    const index = events.findIndex(ev => ev.id === id);
    if (index !== -1) events = removeFromArray(index, events);

    const event = createEvent(
        id,
        playlistId,
        title,
        startMoment,
        endMoment,
        color
    );
    this.setState({
      events: events.concat(event),
      showModal: false,
      clickEvent: ""
    });
    return true;
  };

  removeEvent = id => {
    let {events} = this.state;
    const index = events.findIndex(ev => ev.id === id);
    if (index === -1) return;

    this.setState({events: removeFromArray(index, events)});
  };

  handleEventDelete = event => {
    this.removeEvent(event.id);
    this.setState({
      eventDetails: "",
      showEventDetails: false
    });
  };

  // The event is being resized
  handleResize = info => {
    let {events} = this.state;

    const index = events.findIndex(ev => ev.id === info.event.id);
    const resizedEvent = events[index];

    events = removeFromArray(index, events);

    const event = createEvent(
        resizedEvent.id,
        resizedEvent.playlistId,
        resizedEvent.title,
        moment(info.event.start),
        moment(info.event.end),
        resizedEvent.color
    );

    this.setState({
      showModal: false,
      events: events.concat(event),
      clickEvent: ""
    });
  };

  // An existing event has been moved
  handleDrop = info => {
    let {events} = this.state;

    const index = events.findIndex(e => e.id === info.event.id);
    const event = events[index];

    const startMoment = moment(info.event.start);
    const endMoment = moment(info.event.end);
    this.addEvent(
        this.state.altIsPressed ? id() : event.id,
        event.playlistId,
        event.title,
        event.color,
        startMoment,
        endMoment
    );

    this.setState({
      showModal: false,
      clickEvent: ""
    });
  };

  // Dragging onto the calendar has occured 'external event'
  handleExternalEvent = arg => {
    // TODO arg.event.allDay

    const playlistIndex = parseInt(arg.draggedEl.dataset.index);
    const playlist = this.state.playlists[playlistIndex];

    const start = moment(arg.event.start);
    const end = start.clone().add(1, "h");

    this.addEvent(
        id(),
        playlist.id,
        playlist.name,
        getRandomColor(playlistIndex),
        start,
        end
    );

    this.setState({
      showModal: false
    });

    // Removes the event for the drag so we can insert an event with the parameters we want
    arg.event.remove();
  };

  handleExternalDrop = info => {
  };

  //
  // Other
  //

  showDeletePlaylist = (bool, id) => {
    this.setState({showDeletePlaylist: bool, deletePlaylistId: id});
  };

  exitSchedule = () => {
    if (this.props.history.length > 2) {
        this.props.history.goBack();
    }else{
        window.close();
    }
  };

  handleNameChange = e => {
    if (e.target.value !== "") {
      this.setState({
        name: e.target.value
      });
    }
  };

  handleDuplicate = e => {
    this.setState({showDuplicateConfirmation: true});
  }

  handleArchive = e => {
    this.setState({showArchiveConfirmation: true});
  }

  archive = async () => {
    const {dispatch} = this.props;
    this.setState({showArchiveConfirmation: false});

    try {
      await scheduleActions.archive(dispatch, this.props.id);
      alertActions.notificationSuccess(dispatch, "Schedules archived", this.props.name);
      this.exitSchedule();
    } catch (e) {
      alertActions.notificationError(dispatch, "Error", e.userMessage);
      this.exitSchedule();
    }
  }

  duplicate = async () => {
    const {dispatch} = this.props;
    this.setState({showDuplicateConfirmation: false});

    try {
      /*const id =*/
      await scheduleActions.duplicate(dispatch, this.props.id);
      alertActions.notificationSuccess(dispatch, "Schedules duplicated", this.props.name);
      this.exitSchedule();
      // this.props.history.push(`/schedule/${id}`)
    } catch (e) {
      alertActions.notificationError(dispatch, "Error", "Failed to duplicate schedule");
    }
  }

  isEditingName = bool => {
    this.setState({isEditingName: bool});
  };

  showAddPlaylist = bool => {
    this.setState({showAddPlaylist: bool});
  };

  showPickZone = bool => {
    this.setState({showPickZone: bool});
  };

  addOverride = override => {
    const {events} = this.state;

    override.sTime = moment(override.start).format("HH:mm");
    override.eTime = moment(override.end).format("HH:mm");

    this.setState({
      events: events.concat(override),
      showAddOverride: false,
      showAddInsert: false
    });
  };

  editOverride = override => {
    let currentEvents = this.state.events;

    // current events
    let ceIndex = currentEvents.findIndex(
        ce => ce.id === override.id || ce.id === parseInt(override.id)
    );
    currentEvents = [
      ...currentEvents.slice(0, ceIndex),
      ...currentEvents.slice(ceIndex + 1)
    ];

    override.sTime = moment(override.start).format("HH:mm");
    override.eTime = moment(override.end).format("HH:mm");

    this.setState({
      events: currentEvents.concat(override),
      showAddOverride: false,
      showAddInsert: false
    });
  };

  handleOverrideDelete = override => {
    this.setState({eventDetails: ""}, () => {
      this.handleEventDelete(override.id);
    });
  };

  // Click a date, shows playlist modal
  handleDateClick = arg => {
    if (arg.view.type !== "dayGridMonth") {
      if (
          !haveExistingEventForTimespan(
              this.state.events,
              moment(arg.date) /*moment*/,
              moment(arg.date).add(15, "m") /*moment*/
          )
      ) {
        this.setState({
          clickEvent: arg,
          showModal: true
        });
      }
    }
  };

  handleEventClick = info => {
    if (info.event.rendering !== "background") {
      this.setState({showEventDetails: true, eventDetails: info.event});
    }
  };

  render() {
    const {playlist = [], schedule} = this.props;

    const {
      name,
      events,
      playlists,
      zonesPick,
      showAddPlaylist,
      showPickZone,
      showDeletePlaylist,
      showCancelDecision,
      showEventDetails,
      showModal,
      showAddOverride,
      showAddInsert,
      isEditingName,
      eventDetails,
      clickEvent,
      showSaveConfirmation
    } = this.state;

    const zones = get(schedule, 'zones', []);
    const songSize = get(this.props, 'info.songSize', 0);

    return (
        <ThemeProvider theme={theme}>
                <Container>
                    <ContextMenu id="menu">
                        <MenuItem onClick={this.handleDuplicate}>Duplicate</MenuItem>
                        <MenuItem divider />
                        <MenuItem onClick={this.handleArchive}>Archive</MenuItem>
                    </ContextMenu>

                  {showModal && (
                      <ModalPicker
                          randomColors={RandomColors}
                          playlist={schedule.playlists}
                          addEvent={(playlist, color) => {
                            let eventDuration = Math.min(closestNextEventMinDiff(events, moment(clickEvent.date)), 60);
                            console.log(eventDuration);
                            this.addEvent(
                                id(),
                                playlist.id,
                                playlist.name,
                                color,
                                moment(clickEvent.date),
                                moment(clickEvent.date).add(eventDuration, "m"));
                          }}
                          handleClose={() =>
                              this.setState({showModal: false, clickEvent: ""})
                          }
                          addPlaylist={() => {
                            this.setState({showModal: false}, () =>
                                this.showAddPlaylist(true)
                            );
                          }}
                      />
                  )}
                  {showPickZone && (
                      <ModalPickZone
                          zone={playlist.filter(pl => get(pl, 'client.id', 0) === get(schedule, 'client.id', -1))}
                          zones={zonesPick}
                          handlePickZone={this.pickZone}
                          handleClose={() => this.showPickZone(false)}
                      />
                  )}

                  {playlist.length > 0 &&
                      Object.keys(schedule).length > 0 &&
                      showAddPlaylist && (
                          <ModalAddPlaylist
                              playlist={playlist.filter(pl => get(pl, 'client.id', 0) === get(schedule, 'client.id', -1))}
                              masterPlaylists={playlist.filter(pl => pl.client === undefined)}
                              otherPlaylist={playlists}
                              handleAddPlaylist={this.addPlaylist}
                              handleClose={() => this.showAddPlaylist(false)}
                          />
                      )}

                  {schedule.playlists !== undefined && showDeletePlaylist && (
                      <ModalDecisionBuilder
                          title="Remove Playlist"
                          content="Removing this playlist will remove all the dayparts associated with
              it."
                          handleClose={() => this.showDeletePlaylist(false)}
                          handleAccept={this.deletePlaylist}
                      />
                  )}

                  {showCancelDecision && (
                      <ModalDecisionBuilder
                          title="Quit Schedules"
                          content="Cancelling the schedule will delete all changes."
                          handleClose={() => this.setState({showCancelDecision: false})}
                          handleAccept={this.exitSchedule}
                      />
                  )}

                  {showSaveConfirmation && (
                      <ModalDecisionBuilder
                          title="Save Schedules"
                          size="lg"
                          content={
                            <div>
                                    <div>{`This schedule will be immediately applied to any connected players in ${zones.length} zones.`}</div>
                              {songSize >= 1.3e+10 &&
                                  <WarningMessage>WARNING: The size of all the songs exceeds 13 GB and may not fit
                                            on some players!</WarningMessage>}
                                </div>
                          }
                          handleClose={() => this.setState({showSaveConfirmation: false})}
                          handleAccept={this.saveSchedule}
                      />
                  )}

                  {this.state.showArchiveConfirmation && (
                      <ModalDecisionBuilder
                          title="Archive Schedules"
                          content="Are you sure you want to archive this schedule?"
                          handleClose={() => this.setState({showArchiveConfirmation: false})}
                          handleAccept={this.archive}
                      />
                  )}

                  {this.state.showDuplicateConfirmation && (
                      <ModalDecisionBuilder
                          title="Duplicate Schedules"
                          handleClose={() => this.setState({showDuplicateConfirmation: false})}
                          handleAccept={this.duplicate}
                      />
                  )}

                  {showEventDetails && (
                      <ModalEventDetails
                          event={eventDetails}
                          match={
                              eventDetails.allDay &&
                              events.find(
                                  ev =>
                                      ev.id === eventDetails.id ||
                                      ev.id === parseInt(eventDetails.id)
                              )
                          }
                          color={
                            eventDetails !== "" &&
                            events.find(
                                ev =>
                                    ev.id === parseInt(eventDetails.id) ||
                                    ev.id === eventDetails.id
                            ).color !== undefined
                                ? events.find(
                                    ev =>
                                        ev.id === parseInt(eventDetails.id) ||
                                        ev.id === eventDetails.id
                                ).color
                                : "red"
                          }
                          handleClose={() =>
                              this.setState({showEventDetails: false, eventDetails: ""})
                          }
                          handleEdit={() => {
                            const e = eventDetails.extendedProps ? eventDetails.extendedProps : eventDetails;
                            console.log(e);
                            this.setState({
                              ...(e.insert ? {showAddInsert: true} : {showAddOverride: true}),
                              showEventDetails: false,
                              isEditingDetails: true,
                            })
                          }}
                          handleDelete={this.handleEventDelete}
                      />
                  )}

                  {showAddOverride && (
                      <ModalAddOverride
                          insertMode={false}
                          event={events.find(
                              ce =>
                                  ce.id === eventDetails.id ||
                                  ce.id === parseInt(eventDetails.id)
                          )}
                          handleClose={() =>
                              this.setState({
                                showAddOverride: false,
                                eventDetails: "",
                                isEditingDetails: false
                              })
                          }
                          playlists={playlist}
                          handleSubmit={
                            this.state.isEditingDetails
                                ? this.editOverride
                                : this.addOverride
                          }
                          removeDetails={() => this.setState({eventDetails: ""})}
                      />
                  )}

                  {showAddInsert && (
                      <ModalAddOverride
                          insertMode={true}
                          event={events.find(ce =>
                              ce.id === eventDetails.id || ce.id === parseInt(eventDetails.id)
                          )}
                          handleClose={() =>
                              this.setState({showAddInsert: false, eventDetails: "", isEditingDetails: false})
                          }
                          playlists={playlist}
                          handleSubmit={this.state.isEditingDetails ? this.editOverride : this.addOverride}
                          removeDetails={() => this.setState({eventDetails: ""})}
                      />
                  )}

                  <TitleContainer>
                        <LeftMenuContainer>
                            <EditContainer>
                                {!isEditingName ? (
                                    <React.Fragment>
                                        <Title>{name}</Title>
                                        <Edit icon="pencil-alt" onClick={() => this.isEditingName(!isEditingName)} />
                                        <ContextMenuTrigger id="menu" holdToDisplay={0}>
                                            <img alt="Menu" src={moreImage}
                                                style={{
                                                  width: "26px",
                                                  height: "26px",
                                                  margin: "10px",
                                                  color: "white",
                                                  cursor: "pointer"
                                                }}
                                            />
                                        </ContextMenuTrigger>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Form
                                            type="text"
                                            value={name}
                                            onChange={this.handleNameChange}
                                        />
                                        <Edit
                                            icon={['fal', 'check']}
                                            onClick={() => this.isEditingName(!isEditingName)}
                                        />
                                    </React.Fragment>
                                )}
                            </EditContainer>
                            <InfoContainer>
                                <Active color={zones.length === 0 ? "red" : "green"} data-tip data-for='zones'>
                                    {defineZonesText(zones)}
                                </Active>
                              {zones.length > 0 && (
                                  <ReactTooltip effect='solid' place="bottom" type='light' id='zones'>
                                        {zones.map(z =>
                                            <div key={z.id}>{z.name} ({z.timeZoneOffset})</div>
                                        )}
                                    </ReactTooltip>
                              )}
                              <Text data-tip data-for='songs'>
                                    {get(this.props, 'info.songCount', 0)} songs {bytesToString(songSize)} ({get(this.props, 'info.duplicatedSongs.length', '-')} dups)
                                </Text>
                              {(get(this.props, 'info.duplicatedSongs.length', 0) > 0) && (
                                  <ReactTooltip effect='solid' place="bottom" type='light' id='songs'>
                                        {this.props.info.duplicatedSongs.length} Duplicate Songs:<br /><br />
                                    {this.props.info.duplicatedSongs.map(s =>
                                        <div
                                            key={s.id}>{truncateString(s.title, 35)} - {truncateString(get(s, "artist.name", ""), 35)} ({s.playlistIds.length})</div>
                                    )}
                                    </ReactTooltip>
                              )}
                              <Text>{defineClientText(schedule.client)}</Text>
                                <Text>{defineUpdateText(schedule.dateUpdated)}</Text>
                            </InfoContainer>
                        </LeftMenuContainer>
                        <RightMenuContainer>
                            <CancelButton
                                onClick={this.exitSchedule}
                                // onClick={() => this.setState({ showCancelDecision: true })}
                            >
                                Cancel
                            </CancelButton>
                            <SaveButton onClick={this.attemptSaveSchedule}>Save</SaveButton>
                        </RightMenuContainer>
                    </TitleContainer>
                    <Line />
                    <ContentContainer>
                        <LeftContentContainer>
                            <PlaylistContainer id="external-events">
                                <PlaylistText>PLAYLISTS</PlaylistText>
                              {playlists.sort((p1, p2) => p1.name.localeCompare(p2.name)).map((pl, index) => {
                                const color = getRandomColor(index);
                                const extended = get(this.props.info, 'playlists', []).find(epl => epl.id === pl.id);
                                return (
                                    <Row key={`repeating-playlists-${pl.id}`}>
                                            <Name
                                                className="event-playlist"
                                                data-index={index}
                                                // data-event is read by calendar control for drag events
                                                data-event={`{"title": "${
                                                    pl.name
                                                }", "overlap": true, "color": "${color}", "duration": "01:00" }`}
                                                id={`draggable-text-${index}`}
                                                color={color}
                                                onClick={() => this.props.history.push(`/playlist/${pl.id}`)}
                                            >
                                                {pl.name}
                                              {extended ? <TracksLabel>{extended.songCount} songs</TracksLabel> : ``}
                                            </Name>
                                            <RemoveIcon
                                                icon="minus-circle"
                                                onClick={() => this.showDeletePlaylist(true, pl.id)}
                                            />
                                        </Row>
                                );
                              })}
                              <AddButton onClick={() => this.showAddPlaylist(true)}>
                                    Add Playlist
                                </AddButton>
                            </PlaylistContainer>
                            <PlaylistContainer>
                                <PlaylistText>OVERRIDES</PlaylistText>
                              {(events || []).filter(ev => ev.override)
                                  .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                                  .map((el, index) => {
                                el.allDay = true;
                                return (
                                    <Overrides
                                        key={`overrides-${index}-${el.id}`}
                                        color={el.color}
                                        override={el}
                                        handleClick={() =>
                                            this.setState({
                                              showEventDetails: true,
                                              eventDetails: el
                                            })
                                        }
                                    />
                                );
                              })}
                              <AddButton onClick={() => this.setState({showAddOverride: true})}>
                                    Add Override
                                </AddButton>
                            </PlaylistContainer>
                            <PlaylistContainer>
                                <PlaylistText>INSERTS</PlaylistText>
                              {(events || []).filter(ev => ev.insert)
                                  .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                                  .map((el, index) => {
                                    el.allDay = true;
                                    return (
                                        <Overrides
                                            key={`overrides-${index}-${el.id}`}
                                            color={el.color}
                                            override={el}
                                            handleClick={() =>
                                                this.setState({
                                                  showEventDetails: true,
                                                  eventDetails: el
                                                })
                                            }
                                        />
                                    )
                                  })}
                              <AddButton onClick={() => this.setState({showAddInsert: true})}>
                                    Add Insert
                                </AddButton>
                            </PlaylistContainer>
                        </LeftContentContainer>
                        <CalendarContainer>
                          <ReactTooltip effect='solid' place="bottom" type='light' id='days_tooltip1' />
                            <FullCalendar
                                customButtons={{
                                  zoneButton: {
                                    text: this.state.zonePickName,
                                    click: () => {
                                      this.showPickZone(true)
                                    }
                                  }
                                }}
                                events={events}
                                defaultView="timeGridWeek"
                                height="parent"
                                header={{
                                  left: "prev,next today zoneButton",
                                  center: "title",
                                  right: "dayGridMonth,timeGridWeek,listWeek,listPlugin"
                                }}
                                editable={false}
                                droppable
                                //eventOverlap={false}
                                eventOverlap={(stillEvent, movingEvent) => {
                                  if (stillEvent.rendering === "background") return true;
                                  if (stillEvent.allDay) return true;
                                  return false;
                                }}
                                allDaySlot={true}
                                slotDuration={"00:15:00"}
                                slotLabelInterval={"00:30"}
                                scrollTime={"00:00:00"}
                                plugins={[
                                  dayGridPlugin,
                                  timeGridPlugin,
                                  interactionPlugin,
                                  listPlugin
                                ]}
                                allDayText="Override"
                                ref={this.calendarComponentRef}
                                dateClick={this.handleDateClick}
                                eventReceive={this.handleExternalEvent}
                                eventResize={this.handleResize}
                                eventDrop={this.handleDrop}
                                eventClick={this.handleEventClick}
                                drop={this.handleExternalDrop}
                                eventConstraint={{
                                  startTime: "00:00", // a start time (start of the day in this example)
                                  endTime: "24:00" // an end time (end of the day in this example)
                                }}
                                now={this.state.now}
                                nowIndicator
                                columnHeaderHtml={mom => {
                                  const date = moment(mom);
                                  return `<div style="display: flex; flex-direction: column"><span style="color: gray; font-size: 14px;">${date.format(
                                      "DD/M"
                                  )}</span><span style="margin-top: 10px;">${date.format(
                                      "ddd"
                                  )}</span></div>`;
                                }}
                                eventRender={info => {
                                  // This renders the override with proper css and structure
                                  let duration = moment.duration(info.event.end - info.event.start).asMinutes();
                                  if (info.event.allDay) {
                                    if (info.view.type === "listWeek") {
                                      info.el.innerHTML = `<td class="fc-list-item-time fc-widget-content fc-override">${concatTimeToOverride(
                                          info.event.extendedProps.sTime,
                                          info.event.extendedProps.eTime
                                      )}
                      </td><td class="fc-list-item-marker fc-widget-content fc-override"><span class="fc-event-dot" style="background-color:${info.event.extendedProps.insert ? "green" : "red"}"></span></td><td class="fc-list-item-title fc-widget-content fc-override"><a>${
                                          info.event.title
                                      }</a></td>`;
                                    } else {
                                      const insert = info.event.extendedProps.insert;
                                      if (insert) info.el.classList.add('fc-type-insert');
                                      else info.el.classList.add('fc-type-override');
                                      const daysOfWeek = info.event.extendedProps.daysOfTheWeek;
                                      const days = daysOfWeek ? daysOfWeek.map(d => moment().day(d).format("ddd")).join(", ") : "Everyday";

                                      info.el.innerHTML = `<div title="${days}" data-tip data-for={"days_tooltip1"} class="fc-content" style="display: flex; cursor: pointer">                                        
                                      
                                      <div class="fc-title${insert ? '-insert' : ''}" style="font-size: 16px">${
                                          info.event.title
                                      }</div><div class="fc-time" data-start="${
                                          info.event.start
                                      }" style="margin-left: 5px; display: flex; align-items: center"><span style="color: #ffffff80;">${concatTimeToOverride(
                                          info.event.extendedProps.sTime,
                                          info.event.extendedProps.eTime
                                      )}</span>
                                      <span style="margin-left: 5px; display: flex; align-items: center;
                                      color: ${info.event.extendedProps.weekly ? "#EAAA44" : "white"}">${(info.event.extendedProps.weekly ? " WEEKLY" : " DAILY")}</span>
                                      </div></div><div class="fc-resizer fc-end-resizer"></div>`;
                                    }
                                  } else if (
                                      info.view.type === "timeGridWeek" &&
                                      info.event.rendering !== "background"
                                  ) {
                                    let fontSize = "16px";
                                    if (duration === 15) {
                                      fontSize = "10px";
                                    }
                                    info.el.innerHTML = `<div class="fc-content"><div class="fc-title" style="font-size: ${fontSize}">${
                                        info.event.title
                                    }</div><div class="fc-time" data-start="${moment(
                                        info.event.start
                                    ).format(
                                        "hh:mm A"
                                    )}" style="font-size: ${fontSize}"><span style="color: #ffffff80;">${`${moment(
                                        info.event.start
                                    ).format("HH:mm")} - ${moment(info.event.end).format(
                                        "hh:mm A"
                                    )}`}
                    </span></div>
                    </div><div class="fc-resizer fc-end-resizer"></div>`;
                                  }
                                }}
                            />
                        </CalendarContainer>
                    </ContentContainer>
                </Container>
            </ThemeProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.id || ownProps.match.params.id);
  return {
    id,
    alert: state.alert,
    playlist: state.playlists.calendarlists.list,
    info: getPlaylistsInfo(state),
    schedule: state.schedule.scheduleDetails,
    isReady: !isLoadingCalendarList(state) && isScheduleLoaded(state, id)
  };
};

const connectedCalendarView = withRouter(
    connect(mapStateToProps)(CalendarView)
);
export { connectedCalendarView as CalendarView };
