import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { alertActions, playlistActions } from "_actions";
import { getReactSelectList, Matches } from "_helpers";
import { getActivePlaylistId, getPlaylists, isLoadingList } from "_reducers/playlist.reducer";
import { StandardMoment, UnpublishedBadge } from "_styles";
import { CreatePlaylist } from './CreatePlaylist';
import { FilterSection, PrimaryButton, RowSpaced, SearchFilter, SectionHeader, SectionTitle, SortTable } from './Standard';
import { ModalVerifyPlaylistChanges } from "./ModalVerifyPlaylistChanges";
import { ClientDropdownFilter, SectionGutter } from '../_components';
import get from 'lodash.get';
import { ButtonGroup } from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { uiConstants } from "../_constants";

class Playlists extends React.Component {
  state = {
    showAddPlaylist: false,
    showImportPlaylist: false,
    currentlyPlaying: "",
    name: "",
    search: "",
    addingPlaylist: false,
    publishDialogId: -1,
    filterClientId: -1,
    showExtended: false,
    extendedLoaded: false,
    showOnlyActive: false
  };

  componentDidMount() {
    this.props.dispatch(playlistActions.getSimple());
  }

  componentDidUpdate() {
    if (
        this.state.addingPlaylist &&
        this.props.alert.type === "alert-success"
    ) {
      this.setState({addingPlaylist: false});
      this.props.dispatch(playlistActions.getSimple());
      this.props.dispatch(alertActions.clear());
    } else if (this.props.alert.type === "alert-success") {
      this.props.dispatch(alertActions.clear());
    }
  }

  handleSearch = filterText => {
    this.setState({search: filterText});
  };

  handleShowOnlyActiveChange = (event) => {
    this.setState({showOnlyActive: event.target.checked});
  };


  playlistCreated = playlist => {
    this.showCreatePlaylistModal(false);
    this.props.history.push(`/playlist/${playlist.id}`);
  }

  showCreatePlaylistModal = showAddPlaylist => {
    this.setState({showAddPlaylist});
  };

  showPublishDialog = (id) => {
    this.setState({publishDialogId: id});
  };

  switchExtendedView = (value) => {
    this.setState({showExtended: value});
    if (value && !this.state.extendedLoaded) {
      this.props.dispatch(playlistActions.get());
      this.setState({extendedLoaded: true});
    }

  };

  closePublishDialog = (changesSubmitted) => {
    const {publishDialogId} = this.state;
    if (changesSubmitted === true) {
      console.log('publishing playlist', publishDialogId);
      this.props.playlists.filter(p => p.id === publishDialogId).forEach(p => {
        p['added'] = 0;
        p['removed'] = 0;
      });
    }
    this.setState({publishDialogId: -1});
  }

  filterClient = (client) => {
    this.setState({filterClientId: client ? client.id : -1});
  }

  render() {
    const {showAddPlaylist} = this.state;
    const {
      activePlaylistId,
      client = [],
      playlists = [],
      loading
    } = this.props;

    let data = this.state.filterClientId === -1 ? playlists : playlists.filter(l => get(l, 'client.id', 0) === this.state.filterClientId);
    data = this.state.showOnlyActive ? data.filter(p => p.active) : data;
    data = Matches(data, this.state.search, ["name", "id"]);
    let clientList = getReactSelectList(client, "client");

    const pinColumnWidth = 30;
    let columns = [
      {
        fixed: pinColumnWidth,
        rowRenderer: row => {
          const pinned = activePlaylistId === row.id;
          return (
              <div className="onRowHover">
                            <FontAwesomeIcon icon="thumbtack" size="sm"
                                style={{
                                  color: pinned ? uiConstants.CASSETTE_COLOR : "gray",
                                  cursor: "pointer"
                                }}
                                onClick={e => {
                                  // stop row click handler
                                  e.stopPropagation();
                                  playlistActions.getPlaylist(this.props.dispatch, row.id);
                                  this.props.dispatch(playlistActions.setActivePlaylistId(row.id));
                                }}
                            />
                        </div>
          )
        }
      },
      {percent: 0.15, field: 'id', title: 'Id', isNumber: true},
      {percent: 1, field: 'client.name', title: 'client'},
      {
        percent: 1, field: 'name', title: 'name', rowRenderer: row => (<>
                    <span style={{color: row.active ? "#02FF00" : "transparent"}}>●&nbsp;</span> {row.name}</>)
      }

    ];
    if (this.state.showExtended) {
      columns.push(
          {
            fixed: 120,
            field: row => row.added + row.removed,
            title: 'published',
            isNumber: true,
            rowRenderer: row => (
                (row.added + row.removed) > 0 ? (<UnpublishedBadge onClick={e => {
                  e.stopPropagation();
                  this.showPublishDialog(row.id)
                }} />) : null
            )
          },
          {fixed: 80, title: 'Zones', field: 'zoneCount', isNumber: true},
          {
            fixed: 90, title: 'bpm', field: 'bpms[0]', isNumber: true, collapse: 900, rowRenderer: row => (
                `${row.bpms[0]} - ${row.bpms[1]}`
            )
          });
    }
    columns.push({
      percent: 1, field: 'creator.firstName', title: 'created by', rowRenderer: row => (
          `${row.creator.firstName} ${row.creator.lastName}`
      )
    });
    if (this.state.showExtended) {
      columns.push({
            fixed: 170,
            field: row => row.dateUpdated || row.dateCreated,
            title: 'date updated (Local)',
            isNumber: true,
            dim: true,
            collapse: 1000,
            rowRenderer: row => (
                <StandardMoment>{row.dateUpdated || row.dateCreated}</StandardMoment>
            )
          },
          {fixed: 70, field: 'songsCount', title: 'tracks', isNumber: true, dim: true, collapse: 1100}
      );
    }
    return <>
            {showAddPlaylist && (
                <CreatePlaylist
                    onBack={() => this.showCreatePlaylistModal(false)}
                    onCreated={this.playlistCreated}
                    clientList={clientList}
                />
            )}

      {this.state.publishDialogId !== -1 && (
          <ModalVerifyPlaylistChanges id={this.state.publishDialogId} onClose={this.closePublishDialog} />
      )}

      <SectionHeader>
                <RowSpaced>
                    <SectionTitle>Playlists</SectionTitle>
                    <ButtonGroup>
                        {!this.state.showExtended ? <PrimaryButton onClick={() => this.switchExtendedView(true)}>Extended
                            View</PrimaryButton> : <PrimaryButton onClick={() => this.switchExtendedView(false)}>Simple
                            View</PrimaryButton>}
                      <PrimaryButton style={{marginLeft: 10}}
                          onClick={() => this.showCreatePlaylistModal(true)}>{'+ Add Playlist'}</PrimaryButton>
                    </ButtonGroup>
                </RowSpaced>
                <FilterSection>
                    <SearchFilter onChange={this.handleSearch} value={this.state.search} />
                    <ClientDropdownFilter withMaster={true} clientSelect={this.filterClient} />
                    <FormControlLabel style={{color: '#979797'}} control={<Checkbox style={{color: '#979797'}}
                        checked={this.state.showOnlyActive}
                        onChange={this.handleShowOnlyActiveChange.bind(this)} />}
                        label={"Only active"} />
                </FilterSection>
            </SectionHeader>

            <SortTable
                tableName={'playlists'}
                defaultSortField='client.name'
                loading={loading}
                rows={data}
                gutter={SectionGutter - pinColumnWidth}
                onRowClick={row => {
                  this.props.history.push(`/playlist/${row.id}`)
                }
                }
                columns={columns}
            />

        </>

  }
}

const mapStateToProps = state => ({
  activePlaylistId: getActivePlaylistId(state),
  loading: isLoadingList(state),
  playlists: (getPlaylists(state) || []).map(pl => {
    if (pl.client) return pl;
    return {
      ...pl,
      client: {
        id: 0,
        name: 'Master'
      }
    }
  }),
  alert: state.alert,
  client: state.client.client
});

const connectedPlaylists = withRouter(connect(mapStateToProps)(Playlists));
export { connectedPlaylists as Playlists };

