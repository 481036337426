import styled from "styled-components";
import { uiConstants } from "../_constants";

export const StatusBadge = styled.div`
  display: flex;
  width: 75px;
  height: 22px;
  border-radius: 5px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  color: white;
`;

export const UnpublishedBadge = styled(StatusBadge)`
  background-color: ${uiConstants.CASSETTE_COLOR};
  width: 90px;
  border-radius: 11px;

  &::before {
    content: "Unpublished"
  }
`;

export const AddedBadge = styled(StatusBadge)`
  background-color: ${props => props.theme.onlineGreen};

  &::before {
    content: "Added"
  }
`;

export const PreviouslyRemovedBadge = styled(StatusBadge)`
  background-color: ${props => props.theme.lightYellow};
  &::before {
    content: "Re-Added"
  }
`;

export const RemovedBadge = styled(StatusBadge)`
  background-color: ${props => props.theme.offlineRed};

  &::before {
    content: "Removed"
  }
`;