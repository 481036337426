import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {isLoadingList} from "../../_reducers/playlist.reducer";
import {DefaultButton, DefaultLabel} from "../../_styles";
import {Row,} from '../Standard';
import {Col, Container, Modal} from "react-bootstrap";
import styled from "styled-components";
import {sampleActions} from "../../_actions/sample.actions";
import {ArrowDownward, ThumbDown, ThumbUp} from "@material-ui/icons";
import {Slider} from "@material-ui/core";
import media from "../../_helpers/media";
import {SamplesModal} from "./SamplesStyledWidgets";
import { getPercent, minutesSecondsString, sortSongsBasedOnIndex } from "../Utils";
import TaleaLogoImage from "../../_assets/images/cassette_logo.png";
import { uiConstants } from "../../_constants";

const SongRow = styled(Row)`
    ${media.mobile`    
  margin-top:20px   
`};
    ${media.tablet`
margin-top:40px
`}
    margin-top: 40px;
    height: 70px;
`;

const SliderRow = styled(Row)`
    ${media.mobile`    
  margin-top:0px   
`};

    ${media.tablet`
margin-top:35px
`}
    .MuiSlider-root {
        height: 20px;
    }
`

const SongTitle = styled.div`
    ${media.mobile`    
  font-size: 16px; 
`};
    ${media.tablet`
font-size: 24px;
`}


    color: white;
    margin-bottom: 5px;
    width: 100%;
`;

const SongArtist = styled.div`
    ${media.mobile`    
  font-size: 12px; 
`};
    ${media.tablet`
font-size: 16px;
`}
    font-size: 16px;
    color: #AEAEAE;
    width: 100%
`;
const NextSongTitle = styled('div')`
    margin-top: 5px;
    font-size: 16px;
    color: white;
`;


const NextSongArtist = styled(Col)`
    margin-top: 5px;
    font-size: 16px;
    color: #AEAEAE;
    width: 100%
`;

const SongImageContainer = styled(Col)`
    color: white;
    display: flex;
    flex-direction: column;
    height: 30%;
    width: 30%;
    justify-content: center;
    align-items: center;
`;


const SongImage = styled.img`
    ${media.mobile`    
  height: 100%;
  width: 100%; 
`};
    ${media.tablet`
height: 50%;
  width: 50%;
`}

    color: white;
`;

const NextSongImageContainer = styled(Col)`
`;


const NextSongImage = styled.img`
    height: 70px;
    width: 70px;
`;

const UpNextRow = styled(Row)`
    ${media.mobile`    
  margin-top:50px   
`};
    ${media.tablet`
margin-top:100px
`}
`;


class SongDetails extends React.Component {
  state = {
    id: "",
    currentlyPlaying: "",
    name: "",
    showVoteModal: false,
    feedback: ""
  };

  hideSongDetails = async () => {
    await sampleActions.hideSongDetails(this.props.dispatch);
  }

  async prevSong() {
    let songs = this.props.playlistPlayback.songs;
    if (this.props.playlistPlayback.playlistSortId) {
        songs = sortSongsBasedOnIndex(songs, this.props.playlistPlayback.playlistSortDtos.find(ps => ps.id === this.props.playlistPlayback.playlistSortId).songIds);
    }
    const song = songs.find(s => s.id === this.props.songPlayingId);
    const songIndex = songs.indexOf(song);
    if (songIndex > 0) {
      await sampleActions.playPrevSongInPlaylist(this.props.dispatch);
    }
  }

  async nextSong() {
    await sampleActions.playNextSongInPlaylist(this.props.dispatch);
  }

  async playPausedCLicked() {
    await sampleActions.switchPlayPause(this.props.dispatch, this.props.playing);
  }

  SongItem = function ({song}) {
    if (!song) {
      return <></>;
    }
    const sampleVotes = this.props.sample.votes;
    if (!sampleVotes) {
      return <></>;
    }

    const songVoteClicked = this.songVoteClicked.bind(this);

    const upvoted = sampleVotes.find(sv => sv.song && sv.song.id === song.id && !sv.downvote);
    const downvoted = sampleVotes.find(sv => sv.song && sv.song.id === song.id && sv.downvote);

    return <SongRow>
      <Col xs={8} md={10}>
        <SongTitle>{song.title}</SongTitle>
        <SongArtist>{song.artist.name}</SongArtist>
      </Col>

      <Col xs={4} md={2} className={"d-flex justify-content-end"}>
        <div style={{cursor: "pointer"}} onClick={() => {
          songVoteClicked(song, true)
        }}>
          <ThumbDown style={downvoted ? {color: uiConstants.CASSETTE_COLOR} : {color: "white"}}/>
        </div>

        <div style={{cursor: "pointer", marginLeft: 25}} onClick={() => {
          songVoteClicked(song, false)
        }}>
          <ThumbUp style={upvoted ? {color: uiConstants.CASSETTE_COLOR} : {color: "white"}}/>
        </div>
      </Col>

    </SongRow>;
  }


  submitVoteClicked = async () => {
    await sampleActions.voteSong(this.props.dispatch, this.props.sample.token, this.props.playlistPlayback.id, this.state.songToVote.id, this.state.downvote, this.state.feedback);
    this.setState({
      feedback: "",
      showVoteModal: false
    })
  }

  VoteModal = () => {
    return <SamplesModal centered style={{color: 'white'}} show={true} backdrop={true} size={'small'}
                         onHide={() => {
                           this.setState({showVoteModal: false});
                         }}>
      <Modal.Header style={{
        backgroundColor: uiConstants.CASSETTE_DIALOG_BACKGROUND_COLOR,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: 0
      }} closeButton>
        <Modal.Title style={{width: "90%", paddingLeft: "29%"}}>Vote and comment</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{backgroundColor: uiConstants.CASSETTE_DIALOG_BACKGROUND_COLOR}}>
        <Row style={{marginBottom: 40}}>
          {this.state.downvote ? <ThumbDown style={{fontSize: 40, margin: "auto"}}/> :
              <ThumbUp style={{fontSize: 40, margin: "auto"}}/>
          }
        </Row>
        <Row style={{marginBottom: 40}}>
                    <textarea onChange={(event) => this.setState({feedback: event.target.value})}
                              value={this.state.feedback}
                              placeholder={"Leave a comment..."}
                              style={{
                                color: "white",
                                backgroundColor: "#424052",
                                borderRadius: "15",
                                height: 150,
                                width: "90%",
                                margin: "auto",
                                padding: 20
                              }}/>
        </Row>
        <Row>
          <DefaultButton
              style={{
                height: 45,
                width: 150,
                border: 0,
                color: "black",
                backgroundColor: "#DFDFDF",
                margin: "auto"
              }}
              onClick={async () => {
                await this.submitVoteClicked();
              }}>
            Submit
          </DefaultButton>
        </Row>
      </Modal.Body>
    </SamplesModal>
  }

  songVoteClicked = (song, downvote) => {
    this.setState({showVoteModal: true, songToVote: song, downvote: downvote, feedback: ""});
  }

  playPauseClicked = async () => {
    await sampleActions.switchPlayPause(this.props.dispatch, !this.props.playing);
  }

  render() {
    const {
      playlistPlayback,
      songPlayingId,
    } = this.props;
    let song, nextSongIndex, nextSong;
    if (playlistPlayback) {
      song = playlistPlayback.songs.find(s => s.id === songPlayingId);
      nextSongIndex = (playlistPlayback.songs.indexOf(song) + 1) % playlistPlayback.songs.length;
      nextSong = playlistPlayback.songs[nextSongIndex];
    }
    const SongItem = this.SongItem.bind(this);

    const TaleaSlider = ({value}) => {
      const StyledSlider = styled(Slider)`
          width: 100%;
          padding: 0 !important;
          @media (pointer: coarse) {
              padding: 0 !important;
          }

          .MuiSlider-rail {
              background-color: #3F3F3F;
              top: 10px;
          }

          .MuiSlider-mark {
              color: white;
              background-color: transparent;
              top: 10px;
          }

          .MuiSlider-thumb {
              background-color: transparent;
              top: 10px;
          }

          .MuiSlider-track {
              background-color: white;
              top: 10px;
          }

      ,
      `;
      const handleChange = async (e, newValue) => {
        const currentTime = newValue * .01 * this.props.duration;
        await sampleActions.skipPlaybackTo(this.props.dispatch, currentTime);
      }
      return (
          <StyledSlider value={value} onChange={handleChange}/>
      )
    }

    return <Container style={{...this.props.style, overflow: "hidden"}}>
      <Row style={{height: 20}}>
      </Row>
      <Row>
        <Col xs={4} className={"d-flex justify-content-start"}
             style={{cursor: "pointer", color: "white"}}
             onClick={this.hideSongDetails.bind(this)}>
          <ArrowDownward/>
        </Col>
        <Col xs={4} className={"d-flex justify-content-center"}
             onClick={this.hideSongDetails.bind(this)}>
          <DefaultLabel style={{color: "white"}}>Now Playing</DefaultLabel>
        </Col>
        {/*<Col xs={4} className={"d-flex justify-content-end"}>*/}
        {/*    <FontAwesomeIcon color={"white"} icon={"volume"}/>*/}
        {/*</Col>*/}
      </Row>

      <Row style={{marginTop: 40}}>
        <SongImageContainer>
          <SongImage
              src={song && song.artworkUrl || TaleaLogoImage}/>
        </SongImageContainer>
      </Row>

      <SongItem song={song} songPlayingId={songPlayingId}>
      </SongItem>
      <SliderRow>
        <Col xs={12} className={"d-flex justify-content-center"}>
          <TaleaSlider {...this.props} value={getPercent(this.props.time, this.props.duration)}/>
        </Col>
      </SliderRow>
      <Row style={{color: "#AEAEAE", marginTop: 10}}>
        <Col xs={6} className={"d-flex justify-content-start"}>
          {minutesSecondsString(this.props.time)}
        </Col>
        <Col xs={6} className={"d-flex justify-content-end"}>
          {minutesSecondsString(this.props.duration)}
        </Col>
      </Row>
      <Row style={{marginTop: 30}}>
        <Col xs={12} className={"d-flex justify-content-center"}>
          <FontAwesomeIcon style={{marginTop: 15, cursor: "pointer"}} size={"2x"} color="white"
                           icon={['fas', 'step-backward']} onClick={this.prevSong.bind(this)}/>
          <FontAwesomeIcon style={{marginLeft: 30, marginRight: 30, cursor: "pointer"}} size={"4x"} color="white"
                           icon={['fas', (this.props.playing ? 'pause-circle' : 'play-circle')]}
                           onClick={this.playPauseClicked.bind(this)}/>
          <FontAwesomeIcon style={{marginTop: 15, cursor: "pointer"}} size={"2x"} color="white"
                           icon={['fas', 'step-forward']} onClick={this.nextSong.bind(this)}/>
        </Col>
      </Row>
      <UpNextRow xs={12} className={"d-flex justify-content-start"}>
        <NextSongImageContainer xs={"auto"}>
          <NextSongImage
              src={nextSong && nextSong.artworkUrl || TaleaLogoImage}/>
        </NextSongImageContainer>
        <Col>
          <Row>
            <div style={{color: "#AEAEAE"}}>
              UP NEXT
            </div>
          </Row>
          <Row>
            <NextSongTitle>{nextSong && nextSong.title}</NextSongTitle>
            <NextSongArtist>{nextSong && nextSong.artist.name}</NextSongArtist>
          </Row>
        </Col>
      </UpNextRow>
      {this.state.showVoteModal && <this.VoteModal/>}
    </Container>;

  }
}

const mapStateToProps = (state, props) => ({
  playlistId: props.match.params.playlistId ? parseInt(props.match.params.playlistId) : null,
  loading: isLoadingList(state),
  sample: state.samples.sample,
  songPlayingId: state.samples.songId,
  songProgress: state.samples.songProgress,
  playlistPlayback: state.samples.playlistPlayback,
  playing: state.samples.playing,
  time: state.samples.time,
  duration: state.samples.duration,
  showSongDetails: state.samples.showSongDetails
});
const connectedSongDetails = withRouter(connect(mapStateToProps)(SongDetails));
export {connectedSongDetails as SongDetails};